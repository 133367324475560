import React from "react";
import { CustomerMenu } from "../../components/CustomerMenu";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const RefundCancel = () => {
  return (
    <div className="refund-cancel">
      <div className="div-3">
        <RightMenu className="design-component-instance-node" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-2" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-2" />
        <NavMenu
          className="nav-menu-2"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-2" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <CustomerMenu
          className="customer-menu-2"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=10-114&mode=design"
          href1="https://store.gpakorea.com/cs/faq"
          href2="https://store.gpakorea.com/cs/voc"
          href3="https://store.gpakorea.com/cs/terms_of_use"
          href4="https://store.gpakorea.com/cs/privacy_policy"
          href5="https://store.gpakorea.com/cs/return_policy"
        />
        <div className="RC-wrapper">
          <div className="RC">
            <div className="heading-2">
              <div className="text-wrapper-23">취소 및 환불규정</div>
            </div>
            <div className="div-view-2">
              <p className="element">
                01. 작업 시작 전 100% 환불 가능합니다.
                <br />
                (작업 시작 후에도 결과물이 완성되지 않았을 경우에는 100% 환불 가능합니다.)
              </p>
              <p className="element-2">
                02. 판매자의 사정으로 작업이 당초 약속했던 일자보다 지연되거나 그에 상응하는 사안이 발생하였을 경우
                진행한 작업 건수 또는 작업 노출 일수를 계산하여 제외
                <br />한 금액을 환불 가능합니다.
                <br />
                (1) 작업 건수 환불금액 = 결제금액 - (진행한 작업 건수 × 건단가)
                <br />
                (2) 작업 노출 기간 환불금액 = 결제금액 ÷ 30일 × 작업 노출되지 않은 일수
              </p>
              <p className="element-2">
                03. 협의기간, 작업 기간, 광고기간에 따른 환불 규정
                <br />- 전문가가 작업을 시작하기 전, 의뢰인과 작업 일정 및 가능 여부를 논의하는 &#39;협의기간&#39;에는
                100% 환불 가능합니다.
                <br />- 사전 협의된 광고기간(노출 기간)에는 작업건수/노출기간에 따라 환불 금액이 산출됩니다.
                <br />
                (단, 의뢰인의 과실이나 단순 변심, 광고 매체 규제 등으로 인한 불이익은 환불이 불가)
              </p>
              <p className="element-2">
                04. 결제완료 따른 상품서비스 완료기간은 1주일~3개월 이내입니다.
                <br />- 3개월 이후로 작업이 진행되는 경우는 고객과의 협의 및 요청하에 기간연장을 하거나 작업 건수에 따라
                환불 가능합니다.
                <br />
                [환불금액 = 결제금액 - (진행한 작업 건수 × 건단가)]
              </p>
              <p className="text-wrapper-24">05. 환불은 기본적으로 동일 결제 수단을 통해 환불됩니다.</p>
              <p className="text-wrapper-24">
                06. 캐시 환불은 충전총액으로만 환불 처리 가능합니다.(최소 충전 금액인 10만원 단위로 환불 가능)
              </p>
              <p className="text-wrapper-24">
                07. 캐시 환불 시 서비스캐시(충전서비스 캐시, 작업완료적립 캐시 등)는 환불 금액에서 제외 됩니다.
              </p>
            </div>
          </div>
        </div>
        <div className="footer-frame-wrapper">
          <div className="footer-frame-2">
            <div className="flexcontainer-wrapper">
              <div className="flexcontainer-i">
                <p className="text-i-2">
                  <span className="text-wrapper-25">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-2">
                  <span className="text-wrapper-25">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-2">
                  <span className="text-wrapper-25">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-2">
              <div className="list-item-link-5">
                <div className="text-wrapper-26">블로그</div>
              </div>
              <div className="list-item-link-6">
                <div className="text-wrapper-26">유튜브</div>
              </div>
              <div className="list-item-link-7">
                <div className="text-wrapper-27">페이스북</div>
              </div>
              <div className="list-item-link-8">
                <div className="text-wrapper-28">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-2" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-2" />
        <JimmedProducts className="jimmed-products-2" />
        <ViewedProducts className="viewed-products-2" />
      </div>
    </div>
  );
};
