export const FooterPanel = () => {
    return (
        <div className="footer-frame-wrapper">
          <div className="footer-frame-2">
            <div className="flexcontainer-wrapper">
              <div className="flexcontainer-i">
                <p className="p">
                  <span className="text-wrapper-42">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="p">
                  <span className="text-wrapper-42">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="p">
                  <span className="text-wrapper-42">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-2">
              <div className="list-item-link-11">
                <div className="text-wrapper-43">블로그</div>
              </div>
              <div className="list-item-link-12">
                <div className="text-wrapper-43">유튜브</div>
              </div>
              <div className="list-item-link-13">
                <div className="text-wrapper-44">페이스북</div>
              </div>
              <div className="list-item-link-14">
                <div className="text-wrapper-45">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-2" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>

    );
}
