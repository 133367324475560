import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { getProductPrices } from "../../modules/products";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

export const PdBodyPrice = ({ linkClassName, product_id, onBasket, onPurchase }) => {

  const [ parentCode, setParentCode ] = useState('');
  const [ parentName, setParentName ] = useState('');
  const { user, setUser, global, setGlobal } = useAuth();
  const { data, loading, error } = useSelector(
    (state) => state.productReducer.productprices
  );
  const dispatch = useDispatch();

  const onBasketClick = (price_id, e) => {
    e.preventDefault();
    onBasket(product_id, price_id);
  }

  const onPurchaseClick = (price_id, e) => {
    e.preventDefault();
    onPurchase(product_id, price_id);
  }

  useEffect(() => {
    dispatch(getProductPrices(product_id));
  }, [dispatch, product_id]);

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;

  return (
    <div className="PD-body-price">
      <div className="price-title">가격 정보</div>
      <div className="div-visible-lg">
        <div className="price-heading">단일 상품</div>
        <div className="list">
          <div className="item">

          {data.detail
            .filter((item) => item.parent_code === 'ROOT')
            .map(function(item, index, total) {
              if (index === 0 && parentCode === '') {
                setParentCode(item.code)
                setParentName(item.name)
              } 
              return (
                <div className="link-3">
                  <div className="text-wrapper-11" key={index} >{item.name}</div>
                </div>
            )})}
            
          </div>
        </div>
        <div className="frame" style={{display:'flex'}}>

        {data.detail
        .filter((item) => item.parent_code === parentCode)
        .map((item, index, total) => (
          <div className="table" style={{width: `${Math.floor(704/total)}px`}}>
            <div className="header-row">
              <div className="cell">
                <div className="small">
                  <div className="element-2">구독 : {item.name}</div>
                </div>
                <div className="element-wrapper">
                  <p className="p">
                    <span className="span">{item.final_price.toLocaleString('ko-KR')}</span>
                    <span className="text-wrapper-12">원</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="body-row">
              <div className="data">
                <div className="strong-2">
                  <div className="text-wrapper-13">
                    {parentName}
                  </div>
                </div>
                <div className="small-2">
                  <p className="text-wrapper-14">
                  { item.subscribe === 1 ? <div>
                    <p>중도 해지가 불가능한 서비스입니다.</p>
                    <p>구독 기간 만료 3일전에 다음달 결재 요청 메일이 전송됩니다.</p>
                  </div>
                  :  <div>
                    <p>중도 해지가 불가능한 서비스입니다.</p>
                    <p>구독 기간 만료 3일전에 다음해 결재 요청 메일이 전송됩니다</p>
                    <p>연 단위 구독 시 50% 할인 혜택이 적용됩니다</p>
                    </div>
                  }
                  </p>
                </div>
              </div>
            </div>
            <div className="footer-row">
              <div className="data-2">
                <div className={`link-4 ${linkClassName}`}>
                  <div className="text-wrapper-15" onClick={(e) =>onPurchaseClick(item.id, e)} style={{cursor:'pointer'}}>구매</div>
                </div>
                <div className="link-5">
                  <div className="text-wrapper-16" onClick={(e) =>onBasketClick(item.id, e)} style={{cursor:'pointer'}}>장바구니 담기</div>
                </div>
              </div>
            </div>
          </div>

        ))}

        </div>
      </div>
    </div>
  );
};
