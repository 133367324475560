import * as categoryApi from "../api/categorys";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaTree,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 트리목록
const GET_CATEGORYTREE = "GET_CATEGORYTREE";
const GET_CATEGORYTREE_SUCCESS = "GET_CATEGORYTREE_SUCCESS";
const GET_CATEGORYTREE_ERROR = "GET_CATEGORYTREE_ERROR";

// 상품목록 목록보기
const GET_CATEGORYLIST = "GET_CATEGORYLIST";
const GET_CATEGORYLIST_SUCCESS = "GET_CATEGORYLIST_SUCCESS";
const GET_CATEGORYLIST_ERROR = "GET_CATEGORYLIST_ERROR";

// 상품 항목 조회하기
const GET_CATEGORYDETAIL = "GET_CATEGORYDETAIL";
const GET_CATEGORYDETAIL_SUCCESS = "GET_CATEGORYDETAIL_SUCCESS";
const GET_CATEGORYDETAIL_ERROR = "GET_CATEGORYDETAIL_ERROR";

export const getCategoryTree = (search) => ({
  type: GET_CATEGORYTREE,
  payload: { search: search },
  meta: search,
});

export const getCategoryList = (search) => ({
  type: GET_CATEGORYLIST,
  payload: { search: search },
  meta: search,
});

export const getCategoryDetail = (id) => ({
  type: GET_CATEGORYDETAIL,
  payload: id,
  meta: id,
});

const getCategoryTreeSaga = createPromiseSagaTree(
  GET_CATEGORYTREE,
  categoryApi.getCategoryTree
);

const getCategoryListSaga = createPromiseSagaList(
  GET_CATEGORYLIST,
  categoryApi.getCategoryList
);

const getCategoryDetailSaga = createPromiseSagaDetail(
  GET_CATEGORYDETAIL,
  categoryApi.getCategoryDetail
);

// 사가들을 합치기
export function* categorySaga() {
  yield takeEvery(GET_CATEGORYTREE, getCategoryTreeSaga);
  yield takeEvery(GET_CATEGORYLIST, getCategoryListSaga);
  yield takeEvery(GET_CATEGORYDETAIL, getCategoryDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  categorytree: reducerUtils.initial(),
  categorylist: reducerUtils.initial(),
  categorydetail: reducerUtils.initial(),
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORYTREE:
    case GET_CATEGORYTREE_SUCCESS:
    case GET_CATEGORYTREE_ERROR:
      const reducerTree = handleAsyncActionsList(
        GET_CATEGORYTREE,
        "categorytree",
        true
      );
      return reducerTree(state, action);

    case GET_CATEGORYLIST:
    case GET_CATEGORYLIST_SUCCESS:
    case GET_CATEGORYLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_CATEGORYLIST,
        "categorylist",
        true
      );
      return reducerList(state, action);

    case GET_CATEGORYDETAIL:
    case GET_CATEGORYDETAIL_SUCCESS:
    case GET_CATEGORYDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_CATEGORYDETAIL,
        "categorydetail",
        true
      );
      return reducerDetail(state, action);
    default:
      return state;
  }
}
