import InquiryApi from "../api/inquirys";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */

// 여러개 조회하기
const GET_INQUIRYLIST = "GET_INQUIRYLIST";
const GET_INQUIRYLIST_SUCCESS = "GET_INQUIRYLIST_SUCCESS";
const GET_INQUIRYLIST_ERROR = "GET_INQUIRYLIST_ERROR";

// 상품 항목 조회하기
const GET_INQUIRYDETAIL = "GET_INQUIRYDETAIL";
const GET_INQUIRYDETAIL_SUCCESS = "GET_INQUIRYDETAIL_SUCCESS";
const GET_INQUIRYDETAIL_ERROR = "GET_INQUIRYDETAIL_ERROR";

// 액션 생성 함수
export const getInquiryList = (category, pageNumber, search) => ({
  type: GET_INQUIRYLIST,
  payload: { category: category, page: pageNumber, search: search },
  meta: category,
});
export const getInquiryDetail = (id) => ({
  type: GET_INQUIRYDETAIL,
  payload: id,
  meta: id,
});

// GET_INQUIRYLIST라는 액션이 발생되면 InquiryApi.getInquiryList라는 특정 작업을 수행
const getInquiryListSaga = createPromiseSagaList(
  GET_INQUIRYLIST,
  InquiryApi.getInquiryList
);
const getInquiryDetailSaga = createPromiseSagaDetail(
  GET_INQUIRYDETAIL,
  InquiryApi.getInquiryDetail
);

// 사가들을 합치기(특정액션(GET_INQUIRYLIST)을 모니터링하고 있다가 액션이 발생되면 getInquiryListSaga 인스턴스 실행)
export function* inquirySaga() {
  yield takeEvery(GET_INQUIRYLIST, getInquiryListSaga);
  yield takeEvery(GET_INQUIRYDETAIL, getInquiryDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  inquirylist: reducerUtils.initial(),
  inquirydetail: reducerUtils.initial(),
};

export default function inquiryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INQUIRYLIST:
    case GET_INQUIRYLIST_SUCCESS:
    case GET_INQUIRYLIST_ERROR:
      const reducer = handleAsyncActionsList(
        GET_INQUIRYLIST,
        "inquirylist",
        true
      );
      return reducer(state, action);

    case GET_INQUIRYDETAIL:
    case GET_INQUIRYDETAIL_SUCCESS:
    case GET_INQUIRYDETAIL_ERROR:
      const reducer2 = handleAsyncActionsDetail(
        GET_INQUIRYDETAIL,
        "inquirydetail",
        true
      );
      return reducer2(state, action);
    default:
      return state;
  }
}
