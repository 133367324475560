import React, { useState, useRef, useMemo } from "react";
import { CustomerMenu } from "../../components/CustomerMenu";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillToolbar from "../Common/QuillToolbar"
import DOMPurify from "dompurify";
import InquiryApi from "../../api/inquirys";
import { useAuth } from "../../contexts/auth/auth.context";

// 사용하고 싶은 옵션, 나열 되었으면 하는 순서대로 나열
const toolbarOptions = [
  ["link", "image", "video"],
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
]; 

// 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
export const formats = [
  "link", "image", "video",
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "background",
  "align",
];

export const InquiryCreate = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");

  const titleInput = useRef();
  const commentInput = useRef();

  const onChange = e => {
    setTitle(e.target.value);
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: "#toolbar",
        handlers: {},
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
    };
  }, []);

  const addHours = (date, hours) => {
    let hoursToAdd = hours * 60 * 60 * 1000;
    date.setTime(date.getTime() + hoursToAdd);
    return date;
  }

  const onSubmit = async (e) => {
    const today = new Date();
    let todayLocal = addHours(today, 9);
    let created_at = todayLocal.toISOString().split(".")[0];

    e.preventDefault();
    const formData = {
      solution: "클리커",
      category: "일반문의",
      user_id: user._id,
      name: user.username,
      email: user.email,
      title: title,
      comment: comment,
      created_at: created_at
    }

    InquiryApi.postInquiryCreate(formData).then(response => {

      if(response.status == 200 || response.status == 201) {
        alert("전송이 완료 되었습니다.");
      } else {
        alert("전송 오류입니다.");
      }
    }).catch(error => {
      console.log(error.message);
      alert(`에러: ${error.message}`);
      })
  };
  const [error, setError] = useState("");

  return (
    <div className="inquiry-create">
      <div className="div-2">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <NavMenu
          className="nav-menu-instance"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <CustomerMenu
          className="customer-menu-instance"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=10-114&mode=design"
          href1="https://store.gpakorea.com/cs/faq"
          href2="https://store.gpakorea.com/cs/voc"
          href3="https://store.gpakorea.com/cs/terms_of_use"
          href4="https://store.gpakorea.com/cs/privacy_policy"
          href5="https://store.gpakorea.com/cs/return_policy"
        />
        <div className="inquiry-create-body">
          <div className="ICB">
            <div className="head-area">
              <div className="head-title">1:1 문의</div>
              <div className="head-body">
                <div className="text-wrapper-18">담당자가 확인 후 연락드리겠습니다.</div>
              </div>
            </div>
            <div className="form-area">
              <div className="form-block">
                <div className="title-area">
                  <div className="title-tag">
                    <div className="text-wrapper-19">제목</div>
                  </div>
                  <div className="title-data">
                    <input className="input-2" placeholder="input_box" type="text" name="title" value={title} onChange={onChange}/>
                  </div>
                </div>
                <div className="body-area">
                  <div className="body-tag">
                    <div className="text-wrapper-19">내용</div>
                  </div>
                  <div className="body-data">
                    {/* <div className="input-3"> */}
                      {/* <div className="text-wrapper-20"> */}
                        <QuillToolbar />
                        <ReactQuill
                          theme="snow"
                          style={{ width: "800px", height: "400px" }}
                          modules={modules} formats={formats} name="comment" value={comment} onChange={setComment}                          
                        />
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                  {/* <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(String(comment)),
                    }}
                    style={{
                    marginTop: '30px',
                    overflow: 'hidden',
                    whiteSpace: 'pre-wrap',
                    }} 
                  /> */}
                  
                </div>
              </div>
            </div>
            <div className="button-area">
              <button className="button" onClick={onSubmit}>
                <div className="button-box">문의하기</div>
              </button>
            </div>
          </div>
        </div>
        <div className="footer-panel">
          <div className="footer-frame">
            <div className="company-infos">
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-21">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-21">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-21">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link">
              <div className="list-item-link">
                <div className="text-wrapper-22">블로그</div>
              </div>
              <div className="list-item-link-2">
                <div className="text-wrapper-22">유튜브</div>
              </div>
              <div className="list-item-link-3">
                <div className="text-wrapper-23">페이스북</div>
              </div>
              <div className="list-item-link-4">
                <div className="text-wrapper-24">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-instance" />
        <JimmedProducts className="jimmed-products-instance" />
        <ViewedProducts className="viewed-products-instance" />
      </div>
    </div>
  );
};
