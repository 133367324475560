import axios from "./index";

export const getDeterminedList = async (params) => {
  const response = await axios.get("/determineds/", { params: params });
  return response.data;
};
export const getDeterminedDetail = async (id) => {
  const response = await axios.get(`/determineds/${id}/`);
  return response.data;
};

class DeterminedApi {
  // 판별품 데이터 생성
  static InsertDetermined = (formData) => {
    return axios.post(`/productdetermineds/`, formData, { 
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
    // return axios.post(`/productdetermineds/`, formData, {headers:{"Content-Type": "application/json; charset=utf-8"}});

  };
}
export default DeterminedApi;