/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const MyPageAvata = ({ className }) => {
  return (
    <div className={`my-page-avata ${className}`}>
      <div className="div-profile-image">
        <img className="div-image" alt="Div image" src="/img/div-image-3.png" />
      </div>
      <div className="text-wrapper-14">김연섭 님, 안녕하세요</div>
      <div className="div-my-point">
        <div className="small">
          <div className="text-wrapper-15">보유 캐시</div>
        </div>
        <div className="overlap-group">
          <div className="strong-2">
            <div className="text-wrapper-16">0</div>
          </div>
          <div className="link-3">
            <div className="text-wrapper-17">캐시 충전</div>
          </div>
        </div>
      </div>
    </div>
  );
};
