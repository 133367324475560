import axios from "./index";

// 상품 목록
export const getProductList = async (params) => {
  const response = await axios.get("/products/", { params: params });
  return response.data;
};

// 상품 상세 정보
export const getProductDetail = async (params) => {
  const response = await axios.get(`/products/${params.id}/`) //, { headers: { Authorization: `Bearer ${params.access}` } });
  return response.data;
};

// 특정 상품에 대한 가격 목록
export const getProductPolicys = async (params) => {
  const response = await axios.get(`/productpolicys/${params.category}/`);
  return response.data;
};

// 특정 상품에 대한 리뷰 목록
export const getProductReviews = async (params) => {
  const response = await axios.get(`/productreviews/${params.id}/`);
  return response.data;
};

// 장바구니 목록
export const getProductBaskets = async (params) => {
  const response = await axios.get(`/productbaskets/`, { headers: { Authorization: `Bearer ${params.access}` }});
  return response.data;
};

// 장바구니 항목 추가
export const postProductBasket = (data, header) => {
  const response = axios.post(`/productbaskets/`, data, { headers: { Authorization: `Bearer ${header.access}` }});
  return response;
};

// 장바구니 특정항목 삭제
export const deleteProductBasket = (id, header) => {
  const response = axios.delete(`/productbasketsdelete/${id}/`, { headers: { Authorization: `Bearer ${header.access}` }});
  return response;
};

// 장바구니 선택항목 일괄 삭제
export const deleteProductBasketSelected = (data, header) => {
  const response = axios.post(`/productbasketsdelete/`, data, { headers: { Authorization: `Bearer ${header.access}` }});
  return response;
};

// 특정 상품에 대한 가격 목록
export const getProductPrices = async (params) => {
  const response = await axios.get(`/productprices/${params.id}/`);
  return response.data;
};

// 내가 찜한 상품 목록
export const getProductJjimeds = async (params) => {
  const response = await axios.get(`/productsimilars/${params.category}/`); // , { params: params }
  return response.data;
};

// 내가 본 상품 목록
export const getProductVieweds = async (params) => {
  const response = await axios.get("/productvieweds/", { params: params });
  return response.data;
};

// 상품에 대한 유사 상품 목록
export const getProductSimilars = async (params) => {
  const response = await axios.get(`/productsimilars/${params.category}/`); //, { params: params });
  return response.data;
};

// 장바구니 상품들 구독 신청
export const postProductSubscribes = (data, header) => {
  const response = axios.post(`/productsubscribes/`, data, { headers: { Authorization: `Bearer ${header.access}` }});
  return response;
};


