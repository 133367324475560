import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InquiryCreate } from "../components/InquiryCreate";


export const InquiryCreateContainer = () => {
  return (
    <InquiryCreate />
  );
}
