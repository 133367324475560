import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const MyPurchaseStat = () => {
  return (
    <div className="my-purchase-stat">
      <div className="div-23">
        <RightMenu className="right-menu-9" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-10" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-10" />
        <NavMenu
          className="nav-menu-10"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-10" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <MyPageMenu
          className="my-page-menu-5"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-5" />
        <div className="footer-panel-9">
          <div className="footer-frame-10">
            <div className="company-infos-8">
              <div className="flexcontainer-9">
                <p className="text-i-10">
                  <span className="text-wrapper-119">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-10">
                  <span className="text-wrapper-119">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-10">
                  <span className="text-wrapper-119">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-10">
              <div className="list-item-link-37">
                <div className="text-wrapper-120">블로그</div>
              </div>
              <div className="list-item-link-38">
                <div className="text-wrapper-120">유튜브</div>
              </div>
              <div className="list-item-link-39">
                <div className="text-wrapper-121">페이스북</div>
              </div>
              <div className="list-item-link-40">
                <div className="text-wrapper-122">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-10" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-9" />
        <JimmedProducts className="jimmed-products-10" />
        <ViewedProducts className="viewed-products-10" />
      </div>
    </div>
  );
};
