import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InquiryList } from "../components/InquiryList";
import { getInquiryList } from "../modules/inquirys";

export const InquiryListContainer = () => {
  const [ category, setCategory ] = useState("");
  const [ currPage, setCurrPage ] = useState(1);
  const [ search, setSearch ] = useState("");
  const { data, loading, error } = useSelector(
    (state) => state.inquiryReducer.inquirylist
  );
  const dispatch = useDispatch();

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getInquiryList(category, currPage, search));
  }, [dispatch, category, currPage, search]);

  const onCategory = (paramCategory) => {
    setCategory(paramCategory);
  }

  const onPageSubmit = (paramNumber) => {
    setCurrPage(paramNumber);
  }

  const onSearchSubmit = (paramSearch) => {
    if (paramSearch != "") {
      setCurrPage(1);
      setSearch(paramSearch);
    }
  }

  if (loading && !data) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;
  return (
    <InquiryList 
    category={category} 
    inquirys={data.results} 
    pageInfo={{totalCount: data.count, currPage: currPage, next:data.next, prev:data.previous}}
    onCategory={onCategory} 
    onPageSubmit={onPageSubmit} 
    onSearchSubmit={onSearchSubmit}/>
  );
}

