import { createContext } from 'react';

// type Type = {
//   alert: (message?: any) => Promise<undefined>;
// };

const AlertContext = createContext({
  alert: () => new Promise((_, reject) => reject()),
});

export default AlertContext;
