import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const PurchaseMutong = () => {
  return (
    <div className="purchase-mutong">
      <div className="div-24">
        <RightMenu className="right-menu-8" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-9" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-9" />
        <NavMenu
          className="nav-menu-9"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-9" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <div className="div-25">
          <div className="heading-14">
            <div className="text-wrapper-156">주문하기</div>
          </div>
          <div className="form-3">
            <div className="div-26">
              <div className="div-panel-body-7">
                <div className="div-item-data-3">
                  <div className="div-image-5">
                    <img className="div-img-3" alt="Div img" src="/img/div-img-2.svg" />
                  </div>
                  <div className="div-info-3">
                    <div className="heading-15">
                      <p className="text-wrapper-157">
                        브런치 블로그 상위노출, 브런치 구독, 브런치 좋아요, 브런치 조회수 활성화 마케팅
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table-7">
                  <div className="div-27">
                    <div className="cell-23">
                      <div className="text-wrapper-158">기본항목</div>
                    </div>
                    <div className="cell-24">
                      <div className="text-wrapper-159">수량</div>
                    </div>
                    <div className="cell-25">
                      <div className="text-wrapper-160">가격</div>
                    </div>
                  </div>
                  <div className="body-row">
                    <div className="data-16">
                      <div className="text-wrapper-158">브런치 구독 100건</div>
                      <div className="p-pkg-summary-3">
                        <p className="text-wrapper-161">블로그에 관심있어하는 실제 사용자들로 진행합니다:)</p>
                      </div>
                    </div>
                    <div className="data-17">
                      <div className="div-box-qty-3">
                        <div className="text-wrapper-162">1</div>
                        <div className="link-43">
                          <div className="text-wrapper-163">-</div>
                        </div>
                        <div className="link-44">
                          <div className="text-wrapper-164">+</div>
                        </div>
                      </div>
                    </div>
                    <div className="data-18">
                      <div className="text-wrapper-165">40,000원</div>
                    </div>
                  </div>
                </div>
                <div className="table-8">
                  <div className="body-5">
                    <div className="div-27">
                      <div className="cell-26">
                        <div className="text-wrapper-166">블로그 페이지 URL :</div>
                      </div>
                      <div className="data-19">
                        <div className="div-input-group-7">
                          <div className="textarea-4" />
                        </div>
                      </div>
                    </div>
                    <div className="div-27">
                      <div className="cell-27">
                        <p className="element-5">
                          일 지정 건수(일 최소 지정 건수 10)
                          <br />
                          제한없음(미지정) : 0
                        </p>
                      </div>
                      <div className="data-20">
                        <div className="div-input-group-7">
                          <div className="textarea-5">
                            <div className="div-placeholder-3">
                              <div className="text-wrapper-167">숫자만 입력 가능합니다</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-panel-pay-2">
              <div className="div-panel-heading-3">
                <div className="text-wrapper-168">결제금액</div>
              </div>
              <div className="div-panel-body-8">
                <div className="div-row-3">
                  <div className="div-col-sm-4">
                    <div className="div-box-coupon-3">
                      <div className="div-28">
                        <div className="heading-16">
                          <div className="text-wrapper-169">사용가능한 쿠폰</div>
                          <div className="text-wrapper-170">2</div>
                        </div>
                      </div>
                      <div className="link-45">
                        <div className="text-wrapper-171">쿠폰선택</div>
                      </div>
                    </div>
                    <div className="div-box-point-3">
                      <div className="heading-17">
                        <div className="text-wrapper-172">캐시 사용</div>
                        <div className="small-7">(보유 캐시:0원)</div>
                      </div>
                      <div className="div-input-group-8">
                        <div className="input-8">
                          <div className="div-29">
                            <div className="text-wrapper-173">0</div>
                          </div>
                        </div>
                        <div className="span-input-group-btn-4">
                          <div className="link-46">
                            <div className="text-wrapper-171">전액사용</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div-col-sm-5">
                    <div className="table-9">
                      <div className="body-5">
                        <div className="row-4">
                          <div className="cell-28">총 서비스 금액</div>
                          <div className="data-21">
                            <div className="div-wrapper-6">
                              <div className="text-wrapper-174">40,000원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row-4">
                          <div className="cell-29">ㄴ 쿠폰</div>
                          <div className="data-21">
                            <div className="div-wrapper-6">
                              <div className="text-wrapper-174">0원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row-4">
                          <div className="cell-29">ㄴ 캐시</div>
                          <div className="data-21">
                            <div className="div-wrapper-6">
                              <div className="text-wrapper-174">0원</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="footer-row">
                        <div className="cell-30">총 결제금액</div>
                        <div className="data-22">
                          <div className="p-total-amount-3">
                            <div className="text-wrapper-175">40,000원</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-26">
              <div className="div-panel-heading-3">
                <div className="text-wrapper-176">결제방법</div>
              </div>
              <div className="div-panel-body-9">
                <div className="label-3">
                  <div className="symbol-wrapper-3">
                    <div className="symbol-13"></div>
                  </div>
                  <div className="text-wrapper-177">무통장입금 (세금계산서 미발행)</div>
                </div>
                <div className="label-3">
                  <div className="symbol-wrapper-3">
                    <div className="symbol-14"></div>
                  </div>
                  <div className="text-wrapper-177">카드결제</div>
                </div>
                <div className="label-3">
                  <div className="span-far-3">
                    <div className="symbol-14"></div>
                  </div>
                  <div className="text-wrapper-177">가상계좌 (세금계산서 발행)</div>
                </div>
              </div>
            </div>
            <div className="div-panel-dbank">
              <div className="div-panel-heading-3">
                <div className="text-wrapper-176">입금자 정보</div>
              </div>
              <div className="div-panel-body-10">
                <p className="text-wrapper-178">
                  입력하신 입금자명과 실제 입금자명이 다른 경우 광고가 정상적으로 진행되지 않습니다
                </p>
                <div className="body-row-wrapper">
                  <div className="div-27">
                    <div className="cell-31">
                      <div className="text-wrapper-166">입금자명</div>
                    </div>
                    <div className="data-23">
                      <div className="div-input-group-9">
                        <div className="input-9" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="link-47">
            <div className="overlap-group-6">
              <div className="symbol-15"></div>
              <div className="text-wrapper-179"> 결제하기</div>
            </div>
          </div>
        </div>
        <div className="footer-panel-8">
          <div className="footer-frame-9">
            <div className="company-infos-7">
              <div className="flexcontainer-8">
                <p className="text-i-9">
                  <span className="text-wrapper-180">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-9">
                  <span className="text-wrapper-180">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-9">
                  <span className="text-wrapper-180">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-9">
              <div className="list-item-link-39">
                <div className="text-wrapper-181">블로그</div>
              </div>
              <div className="list-item-link-40">
                <div className="text-wrapper-181">유튜브</div>
              </div>
              <div className="list-item-link-41">
                <div className="text-wrapper-182">페이스북</div>
              </div>
              <div className="list-item-link-42">
                <div className="text-wrapper-183">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-9" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-9" />
        <JimmedProducts className="jimmed-products-9" />
        <ViewedProducts className="viewed-products-9" />
      </div>
    </div>
  );
};
