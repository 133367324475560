import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFaqDetail } from "../modules/faqs";
import FaqDetail from "../components/FaqDetail/FaqDetail";

// 
export const FaqDetailContainer = ( { id }) => {
  const { data, loading, error } = useSelector(
    (state) => state.faqReducer.faqdetail[id]
    ) || {
      loading: false,
      data: null,
      error: null,
    };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFaqDetail(id));
  }, [dispatch, id]);

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;

  return (
    <FaqDetail 
      faq={data}
    />
  );
}

