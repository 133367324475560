import axios from "./index";

export const getCategoryTree = async (params) => {
  const response = await axios.get("/categorys/", { params: params });
  return response.data;
};

export const getCategoryList = async (params) => {
  const response = await axios.get("/categorylist/", { params: params });
  return response.data;
};

export const getCategoryDetail = async (id) => {
  const response = await axios.get(`/categorys/${id}/`);
  return response.data;
};
