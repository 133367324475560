import React from "react";

export const FooterPanel = () => {
  return (
    <div className="footer-panel-3">
      <div className="footer-frame-4">
        <div className="company-infos-2">
          <div className="flexcontainer-3">
            <p className="text-i-4">
              <span className="text-wrapper-58">
                회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호:
                616-86-29484&nbsp;&nbsp;통신판매업: 제2020호-서울마포-2053호
                <br />
              </span>
            </p>
            <p className="text-i-4">
              <span className="text-wrapper-58">
                주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑
                101)&nbsp;&nbsp;전화번호: 010-3930-0624
                <br />
              </span>
            </p>
            <p className="text-i-4">
              <span className="text-wrapper-58">
                이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights
                reserved.
              </span>
            </p>
          </div>
        </div>
        <div className="sns-link-4">
          <div className="list-item-link-13">
            <div className="text-wrapper-59">블로그</div>
          </div>
          <div className="list-item-link-14">
            <div className="text-wrapper-59">유튜브</div>
          </div>
          <div className="list-item-link-15">
            <div className="text-wrapper-60">페이스북</div>
          </div>
          <div className="list-item-link-16">
            <div className="text-wrapper-61">인스타그램</div>
          </div>
        </div>
        <img
          className="logo-gothic-4"
          alt="Logo gothic"
          src="/img/logo-gothic-1.png"
        />
      </div>
    </div>
  );
};
