import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const PurchaseVirtual = () => {
  return (
    <div className="purchase-virtual">
      <div className="div-2">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <NavMenu
          className="nav-menu-instance"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <div className="div-3">
          <div className="heading">
            <div className="text-wrapper-25">주문하기</div>
          </div>
          <div className="form">
            <div className="div-4">
              <div className="div-panel-body">
                <div className="div-item-data">
                  <div className="div-img-wrapper">
                    <img className="div-img" alt="Div img" src="/img/div-img.svg" />
                  </div>
                  <div className="div-info">
                    <div className="heading-2">
                      <p className="p">
                        브런치 블로그 상위노출, 브런치 구독, 브런치 좋아요, 브런치 조회수 활성화 마케팅
                      </p>
                    </div>
                  </div>
                </div>
                <div className="table">
                  <div className="div-5">
                    <div className="cell">
                      <div className="text-wrapper-26">기본항목</div>
                    </div>
                    <div className="cell-2">
                      <div className="text-wrapper-27">수량</div>
                    </div>
                    <div className="cell-3">
                      <div className="text-wrapper-28">가격</div>
                    </div>
                  </div>
                  <div className="div-6">
                    <div className="data">
                      <div className="text-wrapper-26">브런치 구독 100건</div>
                      <div className="p-pkg-summary">
                        <p className="text-wrapper-29">블로그에 관심있어하는 실제 사용자들로 진행합니다:)</p>
                      </div>
                    </div>
                    <div className="div-box-qty-wrapper">
                      <div className="div-box-qty">
                        <div className="text-wrapper-30">1</div>
                        <div className="link-5">
                          <div className="text-wrapper-31">-</div>
                        </div>
                        <div className="link-6">
                          <div className="text-wrapper-32">+</div>
                        </div>
                      </div>
                    </div>
                    <div className="data-2">
                      <div className="text-wrapper-33">40,000원</div>
                    </div>
                  </div>
                </div>
                <div className="body-wrapper">
                  <div className="body">
                    <div className="div-5">
                      <div className="cell-4">
                        <div className="text-wrapper-34">블로그 페이지 URL :</div>
                      </div>
                      <div className="div-input-group-wrapper">
                        <div className="div-input-group">
                          <div className="textarea" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="element-wrapper">
                        <p className="element">
                          일 지정 건수(일 최소 지정 건수 10)
                          <br />
                          제한없음(미지정) : 0
                        </p>
                      </div>
                      <div className="data-3">
                        <div className="div-input-group">
                          <div className="div-placeholder-wrapper">
                            <div className="div-placeholder">
                              <div className="text-wrapper-35">숫자만 입력 가능합니다</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-4">
              <div className="div-panel-heading">
                <div className="text-wrapper-36">결제금액</div>
              </div>
              <div className="div-row-wrapper">
                <div className="div-row">
                  <div className="div-col-sm">
                    <div className="div-box-coupon">
                      <div className="heading-wrapper">
                        <div className="heading-3">
                          <div className="text-wrapper-37">사용가능한 쿠폰</div>
                          <div className="text-wrapper-38">2</div>
                        </div>
                      </div>
                      <div className="link-7">
                        <div className="text-wrapper-39">쿠폰선택</div>
                      </div>
                    </div>
                    <div className="div-box-point">
                      <div className="heading-4">
                        <div className="text-wrapper-40">캐시 사용</div>
                        <div className="small-2">(보유 캐시:0원)</div>
                      </div>
                      <div className="div-input-group-2">
                        <div className="input-2">
                          <div className="div-7">
                            <div className="text-wrapper-41">0</div>
                          </div>
                        </div>
                        <div className="span-input-group-btn">
                          <div className="link-8">
                            <div className="text-wrapper-39">전액사용</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-wrapper">
                    <div className="table-2">
                      <div className="body">
                        <div className="row">
                          <div className="cell-5">총 서비스 금액</div>
                          <div className="data-4">
                            <div className="div-wrapper-3">
                              <div className="text-wrapper-42">40,000원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="cell-6">ㄴ 쿠폰</div>
                          <div className="data-4">
                            <div className="div-wrapper-3">
                              <div className="text-wrapper-42">0원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="cell-6">ㄴ 캐시</div>
                          <div className="data-4">
                            <div className="div-wrapper-3">
                              <div className="text-wrapper-42">0원</div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="cell-7">부가세</div>
                          <div className="p-total-vat-amount-wrapper">
                            <div className="div-wrapper-3">
                              <div className="text-wrapper-42">4,000원</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-6">
                        <div className="cell-8">
                          <div className="text-wrapper-43">총 결제금액</div>
                          <div className="div-wrapper-4">
                            <div className="text-wrapper-44">(VAT 포함)</div>
                          </div>
                        </div>
                        <div className="p-total-amount-wrapper">
                          <div className="p-total-amount">
                            <div className="text-wrapper-45">44,000원</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-panel-pay-method">
              <div className="div-panel-heading">
                <div className="text-wrapper-46">결제방법</div>
              </div>
              <div className="div-panel-body-2">
                <div className="label">
                  <div className="span-far">
                    <div className="symbol-6"></div>
                  </div>
                  <div className="text-wrapper-47">무통장입금 (세금계산서 미발행)</div>
                </div>
                <div className="label">
                  <div className="span-far">
                    <div className="symbol-6"></div>
                  </div>
                  <div className="text-wrapper-47">카드결제</div>
                </div>
                <div className="label">
                  <div className="span-fas">
                    <div className="symbol-7"></div>
                  </div>
                  <div className="text-wrapper-47">가상계좌 (세금계산서 발행)</div>
                </div>
              </div>
            </div>
            <div className="div-panel-taxinvoice">
              <div className="div-panel-heading">
                <div className="text-wrapper-46">세금계산서</div>
              </div>
              <div className="div-panel-body-3">
                <p className="small-3">(세금계산서 미발행 시 관리자에게 따로 연락주세요.)</p>
                <div className="table-3">
                  <div className="body-2">
                    <div className="div-5">
                      <div className="cell-9">
                        <div className="text-wrapper-34">사업자등록번호</div>
                      </div>
                      <div className="data-5">
                        <div className="input-wrapper">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-9">
                        <div className="text-wrapper-34">상호</div>
                      </div>
                      <div className="div-input-group-wrapper">
                        <div className="div-input-group-3">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-9">
                        <div className="text-wrapper-34">대표자명</div>
                      </div>
                      <div className="div-input-group-wrapper">
                        <div className="div-input-group-3">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-9">
                        <div className="text-wrapper-34">업태</div>
                      </div>
                      <div className="div-input-group-wrapper">
                        <div className="div-input-group-3">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-9">
                        <div className="text-wrapper-34">종목</div>
                      </div>
                      <div className="div-input-group-wrapper">
                        <div className="div-input-group-3">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-10">
                        <div className="text-wrapper-34">사업장주소</div>
                      </div>
                      <div className="data-6">
                        <div className="div-input-group-4">
                          <div className="input-4">
                            <div className="div-wrapper-4">
                              <div className="text-wrapper-48">주소</div>
                            </div>
                          </div>
                          <div className="span-input-group-btn-2">
                            <div className="link-9">
                              <p className="symbol-8">
                                <span className="text-wrapper-49"></span>
                                <span className="text-wrapper-50">주소 검색</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="div-input-group-3">
                          <div className="input-5">
                            <div className="div-wrapper-4">
                              <div className="text-wrapper-51">상세 주소</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-9">
                        <div className="text-wrapper-34">담당자명</div>
                      </div>
                      <div className="div-input-group-wrapper">
                        <div className="div-input-group-3">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-9">
                        <div className="text-wrapper-34">담당자 연락처</div>
                      </div>
                      <div className="div-input-group-wrapper">
                        <div className="div-input-group-3">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                    <div className="div-5">
                      <div className="cell-11">
                        <div className="text-wrapper-34">담당자 이메일</div>
                      </div>
                      <div className="data-3">
                        <div className="div-input-group-3">
                          <div className="input-3" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-2">
              <div className="symbol-9"></div>
              <div className="text-wrapper-52"> 결제하기</div>
            </div>
          </div>
        </div>
        <div className="footer-panel">
          <div className="footer-frame">
            <div className="company-infos">
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-53">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-53">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-53">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link">
              <div className="list-item-link">
                <div className="text-wrapper-54">블로그</div>
              </div>
              <div className="list-item-link-2">
                <div className="text-wrapper-54">유튜브</div>
              </div>
              <div className="list-item-link-3">
                <div className="text-wrapper-55">페이스북</div>
              </div>
              <div className="list-item-link-4">
                <div className="text-wrapper-56">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-instance" />
        <JimmedProducts className="jimmed-products-instance" />
        <ViewedProducts className="viewed-products-instance" />
      </div>
    </div>
  );
};
