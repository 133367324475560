import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDeterminDetail } from "../modules/determins.js";
import DeterminDetail from "../components/DeterminDetail/DeterminDetail.jsx";

import { useAuth } from "../contexts/auth/auth.context.js";

export const DeterminDetailContainer = ( { _id }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { data, loading, error } = useSelector(
    (state) => state.determinReducer.determindetail[_id]
    ) || {
      loading: false,
      data: null,
      error: null,
    };
  const dispatch = useDispatch();

  useEffect(() => {
    let mall = "taobao";
    const params = {
      size: 10000,
      query : {
        term : {
          _id : {
            value : `${_id}`
          }
        }    
      }
    }
    dispatch(getDeterminDetail(params));
  }, [dispatch, _id]);

  if (loading) return <div>로딩중...</div>;
  if (error) {
    return (
      <DeterminDetail determin={error.data}/>
    );
  }
  else if (data) {
    return (
      <DeterminDetail determin={data[0]} />
    );
  }
}

