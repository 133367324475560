import React from "react";
import { FooterPanel } from "../../components/FooterPanel";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { useNavigate } from 'react-router-dom';

export const SignupSelect = () => {
  const navigate = useNavigate();

  const onClick = (paramCode, e) => {
    e.preventDefault();
    navigate(`/signupagree?category=${paramCode}`);
  }
  
  return (
    <div className="signup-select">
      <div className="div-6">
        <RightMenu className="right-menu-4" />
        <MainLogo className="main-logo-4" />
        <SearchArea className="search-area-4" />
        <NavMenu className="design-component-instance-node-7" />
        <LinkTree className="link-tree-4" />
        <div className="login-text-2">
          <div className="login-title-2">
            <div className="text-wrapper-33">회원가입</div>
          </div>
          <div className="flexcontainer-wrapper">
            <div className="flexcontainer">
              <p className="text">
                <span className="text-wrapper-34">
                  다양한 포털 계정을 통해 회원 가입을 할 수 있습니다. <br />
                </span>
              </p>
              <p className="text">
                <span className="text-wrapper-34"> 회원 가입시 다양한 서비스를 이용하실 수 있습니다.</span>
              </p>
            </div>
          </div>
        </div>
        <div className="signup-frame">
          <div className="chagaun-signup" style={{cursor: 'pointer'}} onClick={(e)=>{onClick('email', e)}}>
            <img className="img-2" alt="Chagaun logonun" src="/img/chagaun-logonun-1.png" />
            <div className="text-wrapper-35">이메일 회원가입</div>
          </div>
          <div className="naver-signup" style={{cursor: 'pointer'}} onClick={(e)=>{onClick('naver', e)}}>
            <img className="img-2" alt="Naver logo" src="/img/naver-logo-1.png" />
            <div className="text-wrapper-35">네이버 회원가입</div>
          </div>
          <div className="kakao-signup" style={{cursor: 'pointer'}} onClick={(e)=>{onClick('kakao', e)}}>
            <img className="img-2" alt="Kakao logo" src="/img/kakao-logo-1.png" />
            <div className="text-wrapper-35">카카오 회원가입</div>
          </div>
          <div className="google-signup" style={{cursor: 'pointer'}} onClick={(e)=>{onClick('google', e)}}>
            <img className="img-2" alt="Google logo" src="/img/google-logo-1.png" />
            <div className="text-wrapper-35">구글 회원가입</div>
          </div>
        </div>
        <FooterPanel className="footer-panel-4" />
        <KakaoService className="design-component-instance-node-7" />
        <JimmedProducts className="design-component-instance-node-8" />
        <ViewedProducts className="design-component-instance-node-8" />
      </div>
    </div>
  );
};
