import { useParams } from "react-router";
import { DeterminDetailContainer } from "../../containers/DeterminDetailContainer";
import { DeterminListContainer } from "../../containers/DeterminListContainer";

export const DeterminDetailPage = () => {
  const { _id } = useParams();  

  return (
    <DeterminDetailContainer _id={_id} />
  )
}
