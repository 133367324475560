import React, { useState, useRef, useContext, useEffect } from "react";
import { FooterPanel } from "../FooterPanel";
import { JimmedProducts } from "../JimmedProducts";
import { KakaoService } from "../KakaoService";
import { LinkTree } from "../LinkTree";
import { MainLogo } from "../MainLogo";
import { MessagePanel } from "../MessagePanel";
import { NavMenu } from "../NavMenu";
import { PdBodyDesc } from "../PdBodyDesc";
import { PdBodyPriceSide } from "../PdBodyPriceSide";
import { PdBodyPolicy } from "../PdBodyPolicy";
import { PdBodyPrice } from "../PdBodyPrice";
import { PdBodyReview } from "../PdBodyReview";
import { PdBodySimilar } from "../PdBodySimilar";
import { RightMenu } from "../RightMenu";
import { SearchArea } from "../SearchArea";
import { ViewedProducts } from "../ViewedProducts";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import DeterminedApi from "../../api/determineds";
import DeterminApi from "../../api_elastic/determins";
import useForm from '../hooks/useForm';

function DeterminDetail({determin}) {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setDeterminDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState(determin._source.thumb1);
  const [ imageChecked, setImageChecked ] = useState(false);
  const [error, setError] = useState("");
  const [{infringe, content}, onChange, onReset] = useForm({
    'infringe': '',
    'content': ''
  });

  const infringe_options = [
    { value: '', label: 'Select' },
    { value: 'image_copy', label: '이미지도용(모방)' },
    { value: 'image_realbuy', label: '이미지도용(직구)' },
    { value: 'image_theft', label: '이미지도용(공홈)' },
    { value: 'brand_infrange', label: '상표훼손(모자이크)' },
    { value: 'keyword_theft', label: '키워드도용' },
    { value: 'copyright_infrange', label: '저작권침해(캐릭터)' },
    { value: 'genuine', label: '정품' },
  ];
  const [selectedInfringeOption, setSelectedInfringeOption] = useState('');
  const handleSelectInfringeChange = (event) => {
    let value = event.target.value;
    setSelectedInfringeOption(value);
  };

  const refThumbMain = useRef();
  const refProductImage = useRef();
  const refProductFile = useRef();
  const refDeterminImage = useRef();
  const refDeterminFile = useRef();
  const refSrcName = useRef();
  const refSrcDesc = useRef();
  const refSimilarity = useRef();
  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }

  const onDelete = (_id, e) => {
    e.preventDefault();
    const params = {
      query : {
        term : {
          _id : {
            value : `${_id}`
          }
        }    
      }
    }
    DeterminApi.DeleteDetermin(params).then(response => {
      if(response.status == 200) {
        alert(`수집품 데이터가 삭제되었습니다.`);
        navigate('/determin');
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }  

  // validate Input Columns
  const validateInputs =() => {
    if (determinDesc.length <= 0) {
      const result = alert("침해된 이미지를 선택해 주세요!");
      return false;
    }
    else if (selectedInfringeOption.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("침해 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function InsertDeterminedData(frmData) {
    DeterminedApi.InsertDetermined(frmData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("정상적으로 저장되었습니다.")
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  const onSubmit = e => {
    e.preventDefault();
    determin._source.image_similar.map((sim, index, total) => {
      if (sim.checked === true) {
        refProductImage.current = sim.product;
        refProductFile.current = sim.product_file;
        refDeterminImage.current = sim.determin;
        refDeterminFile.current = sim.determin_file;
        refSrcName.current = sim.src_name;
        refSrcDesc.current = sim.src_desc;
        refSimilarity.current = sim.similarity;

      }
    })
    determin._source.official_similar.map((sim, index, total) => {
      if (sim.checked === true) {
        refProductImage.current = sim.product;
        refProductFile.current = sim.product_file;
        refDeterminImage.current = sim.determin;
        refDeterminFile.current = sim.determin_file;
        refSrcName.current = sim.src_name;
        refSrcDesc.current = sim.src_desc;
        refSimilarity.current = sim.similarity;

      }
    })
    determin._source.determined_similar.map((sim, index, total) => {
      if (sim.checked === true) {
        refProductImage.current = sim.product;
        refProductFile.current = sim.product_file;
        refDeterminImage.current = sim.determin;
        refDeterminFile.current = sim.determin_file;
        refSrcName.current = sim.src_name;
        refSrcDesc.current = sim.src_desc;
        refSimilarity.current = sim.similarity;
      }
    })
    if (validateInputs() == true) {
      let frmData = new FormData();
      frmData.append("mall", determin._source.mall);
      frmData.append("category", determin._source.category);
      frmData.append("determin_code", determin._source.determin_code);
      frmData.append("determin_name", determin._source.determin_name);
      frmData.append("product_price", determin._source.product_price);
      frmData.append("price_floor", determin._source.price_floor);
      frmData.append("determin_price", determin._source.determin_price);
      frmData.append("url", determin._source.url);
      frmData.append("src_name", refSrcName.current);
      frmData.append("src_desc", "src_desc"); //refSrcDesc.current);
      frmData.append("similarity", (refSimilarity.current).toFixed(2));
      frmData.append("infringe", selectedInfringeOption);
      frmData.append("content", content);
      frmData.append("product_id", determin._source.product_id);
      frmData.append("seller_id", 5);

      // Product Image Download 
      let downloadedCount = 0;
      if (refProductImage.current.length > 0) {
        fetch(refProductImage.current)
          .then((response) => response.blob())
          .then((blob) => {
            frmData.append("product_image", new File([blob], refProductFile.current));
            downloadedCount += 1;
            if (downloadedCount === 2) 
              InsertDeterminedData(frmData);
          })
          .catch((error) => {
            console.error("Error fetching the file:", error);
            downloadedCount += 1;
            if (downloadedCount === 2) 
              InsertDeterminedData(frmData);
          });
      } else {
        downloadedCount += 1;
        if (downloadedCount === 2) 
          InsertDeterminedData(frmData);
      }

      // Determin Image Download 
      if (refDeterminImage.current.length > 0) {
        fetch(refDeterminImage.current)
          .then((response) => response.blob())
          .then((blob) => {
            frmData.append("determin_image", new File([blob], refDeterminFile.current));
            downloadedCount += 1;
            if (downloadedCount === 2) 
              InsertDeterminedData(frmData);
          })
          .catch((error) => {
            console.error("Error fetching the file:", error);
            downloadedCount += 1;
            if (downloadedCount === 2) 
              InsertDeterminedData(frmData);
          });
      } else {
        downloadedCount += 1;
        if (downloadedCount === 2) 
          InsertDeterminedData(frmData);
      }
    }  
  }
  
  const onImageCheck = (itemname, param_sim, e) => {
    e.preventDefault();
    let desc = '';   
    let date = new Date();
    let tm = date.getTime();
    determin._source.image_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;

          // 선택한 내용 화면 출력
          desc = sim.src_name;
          if (sim.src_desc&&sim.src_desc.length > 0) 
            desc += ' / ' + sim.src_desc;
          desc += ' / ' + sim.similarity.toFixed(2);
          if (infringe.length > 0)
            desc += ' / ' + infringe;
        }
      } else {
        sim.checked = false
      }
    })
    determin._source.official_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;

          // 선택한 내용 화면 출력
          desc = sim.src_name;
          if (sim.src_desc&&sim.src_desc.length > 0) 
            desc += ' / ' + sim.src_desc;
          desc += ' / ' + sim.similarity.toFixed(2);
          if (infringe.length > 0)
            desc += ' / ' + infringe;
        }
      } else {
        sim.checked = false
      }
    })
    determin._source.determined_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;

          // 선택한 내용 화면 출력
          desc = sim.src_name;
          if (sim.src_desc&&sim.src_desc.length > 0) 
            desc += ' / ' + sim.src_desc;
          desc += ' / ' + sim.similarity.toFixed(2);
          if (infringe.length > 0)
            desc += ' / ' + infringe;
        }
      } else {
        sim.checked = false
      }
    })
    setImageChecked(!imageChecked);
    setDeterminDesc(desc);
  }

  const onContentChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    console.log(value);
  };

  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    setThumbClickedUrl(thumburl)
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="determin-detail">
      <div className="div-2">
        {/* // 1.FooterPanel의 className을 "footer-panel-2"로, 2.css->"footer-panel-2에서 모든 !important 떼기,3 메인바디 id="mainbody" 추가하기 */}
        <FooterPanel className="footer-panel-2" bodyBottom={bodyBottom}/>
        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <KakaoService className="design-component-instance-node-2" />
        <LinkTree className="link-tree-instance" />
        <NavMenu className="design-component-instance-node-2" />
        <MainLogo className="main-logo-instance" />
        <SearchArea className="search-area-instance" />
        <RightMenu className="right-menu-instance" />
        <div className="main-frame" id="mainbody">
          <div className="section-frame">
            <div className="frame">
              <div className="frame-2">
                <div className="text-wrapper-17">쇼핑몰 : {determin._source.mall} / {determin._source.category}</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">&nbsp;</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-19">{determin._source.determin_name}</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">{determin._source.determin_price}</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18" href={determin._source.seller_home} target="blank" style={{"color": "blue"}}>판매자 : {determin._source.seller} Home</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18" href={determin._source.url} target="blank" style={{"color": "blue"}}>쇼핑몰 수집품 상세보기</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">수집형태 : {determin._source.search_type}</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">이미지유사도 : {image_similarity_string(determin._source.image_similar)}</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">공홈유사도 : {image_similarity_string(determin._source.official_similar)}</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">판별품유사도 : {image_similarity_string(determin._source.determined_similar)}</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">&nbsp;</div>
              </div>
              <div className="frame-2">
                <div className="text-wrapper-18">&nbsp;</div>
              </div>
            </div>
            <div className="frame-3">
              <div className="img-wrapper">
                <img className="div-image-2" alt="Div image" src={thumbClickedUrl} ref={refThumbMain} name="test.png"/>
              </div>

              <div className="frame-4" >
                  { 
                    [determin._source.thumb1, determin._source.thumb2, determin._source.thumb3, determin._source.thumb4, determin._source.thumb5].map((thumburl, index, total) => (
                      thumburl && thumburl.length > 0 ?
                      <div className={`frame-${index+5}`} style={{cursor:'pointer', backgroundImage: `url(${thumburl})`, backgroundSize:'contain'}} onClick={(e)=>{onThumbClick(thumburl, e)}} key={index} />
                      : null
                    ))
                  }
              </div>
            </div>
          </div>
          <div className="section-frame-2">
            <div className="frame-10">
              <div className="text-wrapper-20">타이틀유사도</div>
            </div>
            <div className="frame-11">
              <div className="frame-12">
                <div className="text-wrapper-21">{determin._source.product_name}</div>
              </div>
              <div className="frame-13">
                <div className="text-wrapper-21">{determin._source.determin_name}</div>
              </div>
            </div>
            <div className="frame-11">
              <div className="frame-14">
                <div className="text-wrapper-21">{determin._source.brand} / {determin._source.model}</div>
              </div>
              <div className="frame-15">
                <div className="text-wrapper-21">{determin._source.title_similarity}</div>
              </div>
            </div>
          </div>
          <div className="section-frame-2">
            <div className="frame-10">
              <div className="text-wrapper-20">가격 유사도</div>
            </div>
            <div className="frame-11">
              <div className="frame-12">
                <div className="text-wrapper-21">130</div>
              </div>
              <div className="frame-13">
                <div className="text-wrapper-21">100</div>
              </div>
            </div>
            <div className="frame-11">
              <div className="frame-14">
                <div className="text-wrapper-21">90</div>
              </div>
              <div className="frame-15">
                <div className="text-wrapper-21">0.802145</div>
              </div>
            </div>
          </div>

          <div className="frame-wrapper">
            <div className="frame-2">
              <div className="text-wrapper-20">이미지 유사도</div>
            </div>
          </div>
          <div className="section-frame-3">
            { determin._source.image_similar && determin._source.image_similar.map((sim, index, total) => (
            <div className="rec-item-frame" key={index}>
              <div className="div-3">
                <div className="thumbnail">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <img className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} />
                    </div>
                    <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('image', sim, e)}}>
                      <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                    </button>
                  </div>
                </div>
                <div className="info-frame">
                  <div className="frame-11">
                    <div className="frame-14">
                      <div className="text-wrapper-21">{sim.src_name}</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">{sim.src_desc}</div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-14">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
              <div className="div-3">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/>
                    </div>
                    <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('image', sim, e)}}>
                      <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                    </button>
                  </div>
                </div>
                <div className="info-frame">
                  <div className="frame-11">
                    <div className="frame-15">
                      <div className="text-wrapper-21">Similarity</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">{sim.similarity}</div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
            </div>
            ))}
          </div>

          <div className="frame-wrapper">
            <div className="frame-2">
              <div className="text-wrapper-20">공홈 유사도</div>
            </div>
          </div>
          <div className="section-frame-3">
            { determin._source.official_similar && determin._source.official_similar.map((sim, index, total) => (
            <div className="rec-item-frame" key={index}>
              <div className="div-3">
                <div className="thumbnail">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <div className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} />
                    </div>
                    <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('official', sim, e)}}>
                      <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                    </button>
                  </div>
                </div>
                <div className="info-frame">
                  <div className="frame-11">
                    <div className="frame-14">
                      <div className="text-wrapper-21">{sim.src_name}</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">{sim.src_desc}</div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-14">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
              <div className="div-3">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/>
                    </div>
                    <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('official', sim, e)}}>
                      <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                    </button>
                  </div>
                </div>
                <div className="info-frame">
                  <div className="frame-11">
                    <div className="frame-15">
                      <div className="text-wrapper-21">Similarity</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">{sim.similarity}</div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
            </div>
            ))}
          </div>

          <div className="frame-wrapper">
            <div className="frame-2">
              <div className="text-wrapper-20">판별품 유사도</div>
            </div>
          </div>
          <div className="section-frame-3">
            { determin._source.determined_similar && determin._source.determined_similar.map((sim, index, total) => (
            <div className="rec-item-frame" key={index}>
              <div className="div-3">
                <div className="thumbnail">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <div className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} />
                    </div>
                    <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('determined', sim, e)}}>
                      <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                    </button>
                  </div>
                </div>
                <div className="info-frame">
                  <div className="frame-11">
                    <div className="frame-14">
                      <div className="text-wrapper-21">{sim.src_name}</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">{sim.src_desc}</div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-14">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
              <div className="div-3">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/>
                    </div>
                    <button className="button" style={{cursor:'pointer'}} onClick={(e)=>{onImageCheck('determined', sim, e)}}>
                      <div className="button-box"><FaHeart size="24px" fill={sim.checked > 0 ? "red" : "gray"}/></div>
                    </button>
                  </div>
                </div>
                <div className="info-frame">
                  <div className="frame-11">
                    <div className="frame-15">
                      <div className="text-wrapper-21">Similarity</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">{sim.similarity}</div>
                    </div>
                  </div>
                  <div className="frame-11">
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                    <div className="frame-15">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>
                  <div className="frame-16" />
                </div>
              </div>
            </div>
            ))}
          </div>
          <form className="section-frame-4" name="frm" onSubmit={onSubmit} encType="multipart/form-data">
            <div className="form-title-frame">
              <div className="form-title">판별하기</div>
            </div>
            <div className="form-caption-frame">
              <div className="form-caption-wrapper">
                <div className="form-caption">판별 : {determinDesc}</div>
              </div>
              <div className="form-determ-tag-wrapper">
                <div className="text-wrapper-22">판별 사유</div>
              </div>
              <select className="form-determ-drop-wrapper" id="infringe" name="infringe" value={selectedInfringeOption} onChange={handleSelectInfringeChange}>
                { infringe_options.map((option, index, total) => ( 
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </div>
            <div className="form-text-frame">
              <textarea className="form-text" name="content" value={content} style={{border:"none"}} onChange={onChange}></textarea>
            </div>
            <div className="form-submit-frame">
              <div className="form-submit-layout" style={{cursor:'pointer'}} onClick={(e)=>{onDelete(determin._id, e)}}>
                <div className="form-submit">삭&nbsp;&nbsp;제</div>
              </div>
              <div className="form-submit-wrapper" style={{cursor:'pointer'}} onClick={onSubmit}>
                <div className="form-submit-2">저&nbsp;&nbsp;장</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default DeterminDetail;