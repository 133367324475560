import React from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { SubMenu } from "./SubMenu.jsx";

export const InquiryList = ({ category, inquirys, pageInfo, onCategory, onPageSubmit, onSearchSubmit }) => {
  return (
    <div className="inquiry-list">
      <div className="div-2">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <NavMenu
          className="nav-menu-instance"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <MyPageMenu
          className="my-page-menu-instance"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-instance" />
        <div className="inquiry-list-body">
          <div className="ILB">
            <div className="inquiry-body-title">
              <div className="span-bar" />
              <div className="heading-FAQ">일대일 문의</div>
            </div>
            <SubMenu category={category} onCategory={onCategory} />
            <div className="inquiry-body-table">
              <div className="form-table">
                <div className="body-row-cell">
                  <div className="text-wrapper-28">번호</div>
                </div>
                <div className="body-row-cell-2">
                  <div className="text-wrapper-29">제목</div>
                </div>
                <div className="body-row-cell-3">
                  <div className="text-wrapper-30">카테고리</div>
                </div>
                <div className="body-row-cell-4">
                  <div className="text-wrapper-31">답변여부</div>
                </div>

                <ul>
                  {inquirys.map((inquiry, index, total) => (
                    <li key={inquiry.id}>

                      <div className={`body-row-data-${1+4*index}`}>
                        <div className="text-wrapper-32">{inquiry.id}</div>
                      </div>
                      <Link to={`/inquirydetail/${inquiry.id}`}>                      
                        <div className={`body-row-data-${2+4*index}`}>
                          <div className="text-wrapper-33">{inquiry.title}</div>
                        </div>
                      </Link>
                      <div className={`body-row-data-${3+4*index}`}>
                        <div className="text-wrapper-34">{inquiry.category}</div>
                      </div>
                      <div className={`body-row-data-${4+4*index}`}>
                        <div className="text-wrapper-35">{inquiry.answer && inquiry.answer.length > 0 ? "완료" : "대기"}</div>
                      </div>

                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <BodyPage 
              className="body-page-instance" 
              pageInfo={ pageInfo } 
              onPageSubmit={onPageSubmit} 
            />
            <BodySearch 
              className="body-search-instance" 
              formInputClassName="design-component-instance-node" 
              pageInfo={pageInfo}
              onSearchSubmit={onSearchSubmit} 
            />
          </div>
        </div>
        <KakaoService className="kakao-service-instance" />
        <JimmedProducts className="jimmed-products-instance" />
        <ViewedProducts className="viewed-products-instance" />
        <div className="footer-panel">
          <div className="footer-frame">
            <div className="company-infos">
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-41">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-41">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-41">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link">
              <div className="list-item-link-7">
                <div className="text-wrapper-42">블로그</div>
              </div>
              <div className="list-item-link-8">
                <div className="text-wrapper-42">유튜브</div>
              </div>
              <div className="list-item-link-9">
                <div className="text-wrapper-43">페이스북</div>
              </div>
              <div className="list-item-link-10">
                <div className="text-wrapper-44">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
