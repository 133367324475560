/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const PdBodyDesc = ({ className, content }) => {
  return (
    <div className={`PD-body-desc ${className}`}>
      <div className="desc-title">상품 소개</div>
      <div className="div-content-inner">
        <p className="element">
          {/* // 판매 정책 */}
          <div dangerouslySetInnerHTML={{__html: content}}></div>
        </p>
      </div>
    </div>
  );
};
