import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { getProductSimilars } from "../../modules/products";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

export const PdBodySimilar = ({ className, category }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { data, loading, error } = useSelector(
    (state) => state.productReducer.productsimilars
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductSimilars(category));
  }, [dispatch, category]);

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;


  return (
    <div className={`PD-body-similar ${className}`}>
      <div className="rec-item">
        <div className="thumbnail">
          <div className="overlap-group">
            <button className="button">
              <div className="button-box"></div>
            </button>
          </div>
        </div>
        <div className="item-info">
          <div className="item-title">
            <p className="item-title-text">
              스마트블록 (카페,블로그) 상위 노출
              <br />
              월보장,건바이 마케팅 진행!
            </p>
          </div>
          <div className="item-price">
            <div className="item-price-text">0원~</div>
          </div>
          <div className="item-review">
            <div className="item-review-symbol"></div>
            <div className="text-wrapper-10"> 4.9</div>
            <div className="item-review-tag">|</div>
            <div className="text-wrapper-10"> 75개의 평가</div>
          </div>
        </div>
      </div>
      <div className="rec-item">
        <div className="thumbnail">
          <div className="overlap-group">
            <button className="button">
              <div className="button-box"></div>
            </button>
          </div>
        </div>
        <div className="item-info">
          <div className="item-title">
            <p className="item-title-text">
              스마트블록 (카페,블로그) 상위 노출
              <br />
              월보장,건바이 마케팅 진행!
            </p>
          </div>
          <div className="item-price">
            <div className="item-price-text">0원~</div>
          </div>
          <div className="item-review">
            <div className="item-review-symbol"></div>
            <div className="text-wrapper-10"> 4.9</div>
            <div className="item-review-tag">|</div>
            <div className="text-wrapper-10"> 75개의 평가</div>
          </div>
        </div>
      </div>
      <div className="rec-item">
        <div className="thumbnail">
          <div className="overlap-group">
            <button className="button">
              <div className="button-box"></div>
            </button>
          </div>
        </div>
        <div className="item-info">
          <div className="item-title">
            <p className="item-title-text">
              스마트블록 (카페,블로그) 상위 노출
              <br />
              월보장,건바이 마케팅 진행!
            </p>
          </div>
          <div className="item-price">
            <div className="item-price-text">0원~</div>
          </div>
          <div className="item-review">
            <div className="item-review-symbol"></div>
            <div className="text-wrapper-10"> 4.9</div>
            <div className="item-review-tag">|</div>
            <div className="text-wrapper-10"> 75개의 평가</div>
          </div>
        </div>
      </div>
      <div className="rec-item">
        <div className="thumbnail">
          <div className="overlap-group">
            <button className="button">
              <div className="button-box"></div>
            </button>
          </div>
        </div>
        <div className="item-info">
          <div className="item-title">
            <p className="item-title-text">
              스마트블록 (카페,블로그) 상위 노출
              <br />
              월보장,건바이 마케팅 진행!
            </p>
          </div>
          <div className="item-price">
            <div className="item-price-text">0원~</div>
          </div>
          <div className="item-review">
            <div className="item-review-symbol"></div>
            <div className="text-wrapper-10"> 4.9</div>
            <div className="item-review-tag">|</div>
            <div className="text-wrapper-10"> 75개의 평가</div>
          </div>
        </div>
      </div>
      <div className="rec-item">
        <div className="thumbnail">
          <div className="overlap-group">
            <button className="button">
              <div className="button-box"></div>
            </button>
          </div>
        </div>
        <div className="item-info">
          <div className="item-title">
            <p className="item-title-text">
              스마트블록 (카페,블로그) 상위 노출
              <br />
              월보장,건바이 마케팅 진행!
            </p>
          </div>
          <div className="item-price">
            <div className="item-price-text">0원~</div>
          </div>
          <div className="item-review">
            <div className="item-review-symbol"></div>
            <div className="text-wrapper-10"> 4.9</div>
            <div className="item-review-tag">|</div>
            <div className="text-wrapper-10"> 75개의 평가</div>
          </div>
        </div>
      </div>
      <div className="rec-item">
        <div className="thumbnail">
          <div className="overlap-group">
            <button className="button">
              <div className="button-box"></div>
            </button>
          </div>
        </div>
        <div className="item-info">
          <div className="item-title">
            <p className="item-title-text">
              스마트블록 (카페,블로그) 상위 노출
              <br />
              월보장,건바이 마케팅 진행!
            </p>
          </div>
          <div className="item-price">
            <div className="item-price-text">0원~</div>
          </div>
          <div className="item-review">
            <div className="item-review-symbol"></div>
            <div className="text-wrapper-10"> 4.9</div>
            <div className="item-review-tag">|</div>
            <div className="text-wrapper-10"> 75개의 평가</div>
          </div>
        </div>
      </div>
    </div>
  );
};
