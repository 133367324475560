import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";

export const CustomerMenu = ({ className, href, href1, href2, href3, href4, href5 }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();

  // 공지사항
  const onBulletinList = () => {
    navigate("/bulletinlist");
  };

  // 자주묻는 질문
  const onFaqList = () => {
    navigate("/faqlist");
  };
    
  // 1:1 문의
  const onInquiryCreate = () => {
    navigate("/inquirycreate");
  };
    
  // 이용약관
  const onTermsOfUse = () => {
    navigate("/termsofuse");
  };
    
  // 개인정보취급방침
  const onRefundCancel = () => {
    navigate("/personalprivacy");
  };
    
  // 취소 및 환불규정
  const onMyInviteLink = () => {
    navigate("/refundcancel");
  };
    
  return (
    <div className={`customer-menu ${className}`}>
      <div className="customer-item">
        <div className="span-wrapper">
          <div className="span">
            <div className="text-wrapper-9" onClick={onBulletinList} rel="noopener noreferrer" target="_blank">
              공지사항
            </div>
          </div>
        </div>
        <div className="item-link-7" rel="noopener noreferrer" target="_blank">
          <div className="text-wrapper-10" onClick={onFaqList} rel="noopener noreferrer" target="_blank">
            자주묻는 질문
          </div>
        </div>
        <div className="item-link-7" rel="noopener noreferrer" target="_blank">
          <div className="text-wrapper-10" onClick={onInquiryCreate} rel="noopener noreferrer" target="_blank">
            1:1 문의
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-10" onClick={onTermsOfUse} rel="noopener noreferrer" target="_blank">
            이용약관
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-10" onClick={onRefundCancel} rel="noopener noreferrer" target="_blank">
            개인정보취급방침
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-10" onClick={onMyInviteLink} rel="noopener noreferrer" target="_blank">
            취소 및 환불규정
          </div>
        </div>
      </div>
      <div className="customer-title">
        <div className="text-wrapper-11">고객센터</div>
      </div>
    </div>
  );
};

CustomerMenu.propTypes = {
  href: PropTypes.string,
  href1: PropTypes.string,
  href2: PropTypes.string,
  href3: PropTypes.string,
  href4: PropTypes.string,
  href5: PropTypes.string,
};
