import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { getProductPolicys } from "../../modules/products";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

export const PdBodyPolicy = ({ className, category }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { data, loading, error } = useSelector(
      (state) => state.productReducer.productpolicys
  );
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getProductPolicys(category));
  }, [dispatch, category]);

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;

  return (
    <div className={`PD-body-policy ${className}`}>
      <div className="heading">판매 정책</div>
      <div className="div-content-inner-2">

       {data.detail.map((policy, index, total) => (
        <p className="element-3">
          {/* // 판매 정책 */}
          <div dangerouslySetInnerHTML={{__html: policy.policy_text}}></div>
        </p>
      ))}

      </div>

    </div>
  );
};
