/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Input = ({ className, name, value, onChange, text = "input_box", inputType = "text" }) => {
  return <input className={`input ${className}`} placeholder={text} type={inputType} name={name} value={value} onChange={onChange}/>;
};

Input.propTypes = {
  text: PropTypes.string,
  inputType: PropTypes.string,
};
