import React from "react";
import PropTypes from "prop-types";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ userData, globalData, children }) => {
  let [user, setUser] = React.useState(userData);
  user = typeof user === "string" ? JSON.parse(user) : user;

  let [global, setGlobal] = React.useState(globalData);
  global = typeof global === "string" ? JSON.parse(global) : global;

  return (
    <AuthContext.Provider value={{ user, setUser, global, setGlobal }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  userData: PropTypes.any,
  globalData: PropTypes.any,
  children: PropTypes.any,
};

export const useAuth = () => React.useContext(AuthContext);
