import React, { useState, useRef, useContext, useCallback } from "react";
import { FooterPanel } from "../FooterPanel";
import { JimmedProducts } from "../JimmedProducts";
import { KakaoService } from "../KakaoService";
import { LinkTree } from "../LinkTree";
import { MainLogo } from "../MainLogo";
import { MessagePanel } from "../MessagePanel";
import { NavMenu } from "../NavMenu";
import { PdBodyDesc } from "../PdBodyDesc";
import { PdBodyPriceSide } from "../PdBodyPriceSide";
import { PdBodyPolicy } from "../PdBodyPolicy";
import { PdBodyPrice } from "../PdBodyPrice";
import { PdBodyReview } from "../PdBodyReview";
import { PdBodySimilar } from "../PdBodySimilar";
import { RightMenu } from "../RightMenu";
import { SearchArea } from "../SearchArea";
import { ViewedProducts } from "../ViewedProducts";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaRegHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";

function DeterminedDetail({product}) {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ jjimed, setJjimed ] = useState(0);
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);

  const refTab = useRef();
  const refDesc = useRef();
  const refPrice = useRef();
  const refPolicy = useRef();
  const refReview = useRef();
  const navigate = useNavigate();

  function onJump(paramJump, e) {
    if (paramJump === 'Desc') {
      setJump(paramJump);
      refDesc.current?.scrollIntoView({ alignTop:'true', behavior:'smooth' });
    } else if (paramJump === 'Price') {
      setJump(paramJump);
      refPrice.current?.scrollIntoView({ alignTop:'true', behavior:'smooth' });
    } else if (paramJump === 'Policy') {
      setJump(paramJump);
      refPolicy.current?.scrollIntoView({ alignTop:'true', behavior:'smooth' });
    } else if (paramJump === 'Review') {
      setJump(paramJump);
      refReview.current?.scrollIntoView({ alignTop:'true', behavior:'smooth' });
    }
    else {
      setJump('');
    }
  }

  // 상품 장바구니 넣기
  function onBasket(product_id, price_id) {
    const formData = {
      product_id: product_id,
      price_id: price_id
    }
    productApi.postProductBasket(formData, {access: user.access}).then(async response => {
      if(response.status == 200 && response.data.code === "success") {
        const result = await confirmComp('장바구니에 넣었습니다. 장바구니로 이동하시겠습니까?');
        if (result === true) {
          navigate("/productbaskets");
        }
      } else {
        alert(response.data.detail)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  // 상품 구매하기
  function onPurchase(product_id, price_id) {
    const formData = {
      product_id: product_id,
      price_id: price_id
    }
    productApi.postProductSubscribes(data=formData, header={ access: user.access }).then(response => {
      if(response.status == 200) {
        alert(response.data.msg)
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
    
  }


  const handleScroll = useCallback(() => {
    if (!refTab.current || !refDesc.current || !refPrice.current || !refPolicy.current || !refReview.current || !document.getElementById('app'))
      return;

    // 스크롤의 실시간 위치
    const scrollTop = document.getElementById('app')?.scrollTop;
    const offset = window.pageYOffset;

    // 스크롤 위치가 refTab(하위메뉴 탭)의 위치보다 아래이면
    if (scrollTop >= refTab.current.offsetTop - offset) {
      setFixedTab(true);
    } else {
      setFixedTab(false);
    }
    console.log(`scrollTop=${scrollTop}, offset=${offset}, fixedTab=${fixedTab}`)

    // 스크롤 위치가 detailRef(하위메뉴 2번)의 위치보다 위이면
    if (scrollTop < refPrice.current.offsetTop - offset) {
      setJump('Desc');
    } 
    // 스크롤 위치가 detailRef(하위메뉴 2번)의 위치이거나 아래이면
    else if (scrollTop < refPolicy.current.offsetTop - offset) {
      setJump('Price');
    } 
    else if (scrollTop < refReview.current.offsetTop - offset) {
      setJump('Policy');
    } 
    else if (scrollTop >= refReview.current.offsetTop - offset) {
      setJump('Review');
    } 
  }, [refDesc.current, refPrice.current, refPolicy.current, refReview.current]);

  return (
    <div className="product-detail">
      <div className="div-2">
        <FooterPanel className="footer-panel-instance" />
        <JimmedProducts className="design-component-instance-node" />
        <ViewedProducts className="design-component-instance-node" />
        <KakaoService className="design-component-instance-node-2" />
        <PdBodySimilar className="PD-body-similar-instance" category={product.detail.category} />

        {/* // 장애 발생시 페이지와 오버랩 */}
        <div className="overlap" style={{paddingTop:'200px'}}>
        { product.code != 'success' ? (
          <MessagePanel className="message-panel-instance" code={product.code} detail={product.detail} />
        ) : (
          <div className="product-detail-body">
            <div className="div-item-wrap">
              <div className="PD-body-thumbnail">
                <div className="div-item-rate">
                  <div className="div-stars-2">
                    <div className="symbol-12"><FaRegStar size="17px" color="#ffa500" /></div>
                    <div className="symbol-12"><FaRegStar size="17px" color="#ffa500" /></div>
                    <div className="symbol-12"><FaRegStar size="17px" color="#ffa500" /></div>
                    <div className="symbol-12"><FaRegStar size="17px" color="#ffa500" /></div>
                    <div className="symbol-12"><FaRegStar size="17px" color="#ffa500" /></div>
                  </div>
                  <div className="text-wrapper-42">{product.detail.grade}</div>
                  <div className="p-count">
                    <div className="text-wrapper-43">({reviewed}개의 평가)</div>
                  </div>
                </div>
                <div className="div-image-2">
                  <div className="div-3" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${product.detail.thumbmain})`, backgroundSize:'corner'}} />
                </div>
              </div>
              <PdBodyPriceSide
                className="PD-body-price-side-instance"
                linkClassName="design-component-instance-node-3"
                product_id={product.detail.id}
                onBasket={onBasket}
                onPurchase={onPurchase}
              />

              {/* // 서브카테고리(상품소개, 가격정보, 판매정책, 리뷰) 영역 , zIndex:'100'*/}
              <div className="PD-body-tab" ref={refTab} style={{position: fixedTab === true ? 'fixed' : '', left:'0px', width:'100%', height:'60px', backgroundColor:'#ffffff'}}>
                <div className="div-container">
                  <div className="list-3">
                    <div className="item-margin">
                      <div className="item-link-11" onClick={(e) => {onJump('Desc', e)}} style={{cursor:'pointer', backgroundColor: jump === 'Desc' ? '#ffffff' : '#f3f4f8', borderColor: jump === 'Desc' ? '#000000' : '#e4e5ed'}}>
                        <div className="text-wrapper-44">상품 소개</div>
                      </div>
                    </div>
                    <div className="item-link-wrapper">
                      <div className="item-link-11" onClick={(e) => {onJump('Price', e)}} style={{cursor:'pointer', backgroundColor: jump === 'Price' ? '#ffffff' : '#f3f4f8', borderColor: jump === 'Price' ? '#000000' : '#e4e5ed'}}>
                        <div className="text-wrapper-45">가격 정보</div>
                      </div>
                    </div>
                    <div className="item-margin-2">
                      <div className="item-link-11" onClick={(e) => {onJump('Policy', e)}} style={{cursor:'pointer', backgroundColor: jump === 'Policy' ? '#ffffff' : '#f3f4f8', borderColor: jump === 'Policy' ? '#000000' : '#e4e5ed'}}>
                        <div className="text-wrapper-45">판매 정책</div>
                      </div>
                    </div>
                    <div className="item-margin-3">
                      <div className="item-link-11" onClick={(e) => {onJump('Review', e)}} style={{cursor:'pointer', backgroundColor: jump === 'Review' ? '#ffffff' : '#f3f4f8', borderColor: jump === 'Review' ? '#000000' : '#e4e5ed'}}>
                        <div className="text-wrapper-46" >상품 리뷰</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* // 상품 소개 */}
              <div ref={refDesc}>
              <PdBodyDesc className="design-component-instance-node-4" content={product.detail.content} />
              </div>
              
              {/* // 가격 정보  */}
              <div ref={refPrice}>
              <PdBodyPrice
                className="PD-body-price-instance"
                divVisibleLgClassName="PD-body-price-2"
                linkClassName="design-component-instance-node-3"
                product_id={product.detail.id}
                onBasket={onBasket}
                onPurchase={onPurchase}
              />
              </div>

              {/* // 판매 정책  */}
              <div ref={refPolicy}>
              <PdBodyPolicy className="design-component-instance-node-4" category={product.detail.category} />
              </div>

              {/* // 상품 리뷰  */}
              <div ref={refReview}>
              <PdBodyReview className="PD-body-review-instance" product_id={product.detail.id} product_grade={product.detail.grade} />
              </div>
            </div>
          </div>
        ) }
        </div>

        {/* // 상단 헤더 부문 스크롤시 고정 */}
        <header style={{position:'fixed', backgroundColor:'#ffffff', width:'100%', height:'200px'}}>
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <NavMenu
          className="design-component-instance-node-2"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        </header>
      </div>
    </div>
  );
};
export default DeterminedDetail;