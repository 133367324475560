import PropTypes from "prop-types";
import React, {useContext} from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth/auth.context";
import AuthApi from "../../api/auth";
import AlertContext from '../../contexts/alert/AlertContext';

export const RightMenu = ({ className, afterClassName, afterClassNameOverride, divClassName, href }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();
  const { alert: alertComp } = useContext(AlertContext);

  // 로그인
  const onLogin = () => {
    navigate("/login");
  };

  // 회원가입
  const onSignup = () => {
    navigate("/signupselect");
  };

  // 로그아웃
  const onLogout = () => {
    AuthApi.Logout(user);
    setUser(null);
    alertComp("로그아웃 되었습니다. 감사합니다.");
    navigate("/");
  };

  // 마이페이지
  const onMypage = () => {
    navigate("/mypurchaselist");
  };

  // 고객센터
  const onCustomerCenter = () => {
    navigate("/bulletinlist");
  };

  // 장바구니
  const onPurchaseBasket = () => {
    if (user && user.access.length >= 100) {
      navigate("/productbaskets");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className={`right-menu ${className}`}>
      { user && user.access.length > 200 ? (
      <div>
        <div className="item-link-6" style={{cursor:'pointer'}}>
          <div className="text-wrapper-38" onClick={onLogout}>로그아웃</div>
          <div className={`after-5 ${afterClassName}`} />
        </div>
        <div className="item-link-7" style={{cursor:'pointer'}}>
          <div className="text-wrapper-39" onClick={onMypage}>마이페이지</div>
          <div className={`after-5 ${afterClassNameOverride}`} />
        </div>
      </div>
      ) : (
      <div>
        <div className="item-link-6" style={{cursor:'pointer'}}>
          <div className="text-wrapper-38" onClick={onLogin}>로그인</div>
          <div className={`after-5 ${afterClassName}`} />
        </div>
        <div className="item-link-7" style={{cursor:'pointer'}}>
          <div className="text-wrapper-39" onClick={onSignup}>회원가입</div>
          <div className={`after-5 ${afterClassNameOverride}`} />
        </div>
      </div>
      )}
      <div className="item-link-8" style={{cursor:'pointer'}}>
        <div className="text-wrapper-40" onClick={onCustomerCenter}>
          고객센터
        </div>
        <div className={`after-6 ${divClassName}`} />
      </div>
      <div className="item-link-9" style={{cursor:'pointer'}}>
        <div className="text-wrapper-41" onClick={onPurchaseBasket}>장바구니</div>
      </div>
    </div>
  );
};

RightMenu.propTypes = {
  href: PropTypes.string,
};
