import axios from "./index";

export const getBulletinList = async (params) => {
  const response = await axios.get("/bulletins/", {params: params});
  return response.data;
};
export const getBulletinDetail = async (id) => {
  const response = await axios.get(`/bulletins/${id}/`);
  return response.data;
};

