import { useParams } from "react-router";
import { InquiryDetailContainer } from "../../containers/InquiryDetailContainer";
import { InquiryListContainer } from "../../containers/InquiryListContainer";

export const InquiryDetailPage = () => {
  const { id } = useParams();  
  return (
    (!isNaN(+id) === true) ?
    <InquiryDetailContainer id={parseInt(id)} /> :
    <InquiryListContainer />
  );
}

