import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const MyInfoUpdate = () => {
  return (
    <div className="my-info-update">
      <div className="div-2">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <NavMenu
          className="nav-menu-instance"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <div className="footer-panel">
          <div className="footer-frame">
            <div className="company-infos">
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-30">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-30">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-30">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link">
              <div className="list-item-link">
                <div className="text-wrapper-31">블로그</div>
              </div>
              <div className="list-item-link-2">
                <div className="text-wrapper-31">유튜브</div>
              </div>
              <div className="list-item-link-3">
                <div className="text-wrapper-32">페이스북</div>
              </div>
              <div className="list-item-link-4">
                <div className="text-wrapper-33">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-instance" />
        <MyPageMenu
          className="my-page-menu-instance"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-instance" />
        <div className="form-wrapper">
          <div className="form">
            <div className="div-profile-image-2">
              <img className="link-6" alt="Link" src="/img/link.svg" />
              <div className="input-3">
                <div className="input-4">
                  <div className="text-wrapper-34">파일 선택</div>
                </div>
                <div className="text-wrapper-35">선택된 파일 없음</div>
              </div>
            </div>
            <div className="div-input-wrap">
              <div className="label">
                <div className="text-wrapper-36">이메일</div>
                <div className="text-wrapper-37">*</div>
              </div>
              <div className="div-input-group">
                <div className="input-5">
                  <input className="div-3" placeholder="kyss5530@naver.com" type="email" />
                </div>
              </div>
            </div>
            <div className="div-input-wrap-2">
              <div className="label-2">
                <div className="text-wrapper-38">비밀번호</div>
              </div>
              <div className="label-3">
                <div className="span-far">
                  <div className="symbol-10"></div>
                </div>
                <div className="text-wrapper-39">비밀번호 변경</div>
              </div>
            </div>
            <div className="div-input-wrap">
              <div className="label">
                <div className="text-wrapper-36">이름</div>
                <div className="text-wrapper-37">*</div>
              </div>
              <div className="div-input-group">
                <div className="input-6">
                  <div className="div-4">
                    <div className="text-wrapper-40">김연섭</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-input-wrap-3">
              <div className="label">
                <div className="text-wrapper-36">휴대폰번호</div>
                <div className="text-wrapper-37">*</div>
              </div>
              <div className="div-input-group">
                <div className="input-7">
                  <div className="div-4">
                    <div className="text-wrapper-40">010-3930-0624</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-btn-wrap">
              <div className="link-7">
                <div className="text-wrapper-41">회원정보 수정</div>
              </div>
              <div className="link-8">
                <div className="text-wrapper-42">회원탈퇴</div>
              </div>
            </div>
          </div>
        </div>
        <JimmedProducts className="jimmed-products-instance" />
        <ViewedProducts className="viewed-products-instance" />
      </div>
    </div>
  );
};
