import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { getProductPrices } from "../../modules/products";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaAngleDown, FaAngleUp, FaRegHeart } from 'react-icons/fa6'

export const PdBodyPriceSide = ({ className, linkClassName, product_id, onBasket, onPurchase }) => {
  const [jjimed, setJjimed] = useState(0);
  const [ currentParentCode, setCurrentParentCode ] = useState('');
  const [ currentPriceId, setCurrentPriceId ] = useState('');
  const { user, setUser, global, setGlobal } = useAuth();
  const { data, loading, error } = useSelector( (state) => state.productReducer.productprices );
  const dispatch = useDispatch();

  function onCategory(paramCode, e) {
    if (paramCode === currentParentCode) {
      setCurrentParentCode('NoDisplay');
    }
    else {
      setCurrentParentCode(paramCode);
    }
  }

  const onBasketClick = (price_id, e) => {
    e.preventDefault();
    onBasket(product_id, price_id);
  }

  const onPurchaseClick = (price_id, e) => {
    e.preventDefault();
    onPurchase(product_id, price_id);
  }

  useEffect(() => {
    dispatch(getProductPrices(product_id));
  }, [dispatch, product_id]);

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;

  return (
    <div className={`PD-body-price-side ${className}`}>
      <div className="div-item-top">
        <div className="div-item-share">
          <div className="link-6">
            <div className="symbol-8"></div>
          </div>
        </div>
        <div className="div-item-fav">
          <div className="link-7">
            <div className="symbol-9"><FaRegHeart size="21px" color="#ff00ff" />&nbsp;</div>
            <div className="text-wrapper-25">&nbsp;찜하기&nbsp;</div>
            <div className="text-wrapper-26">&nbsp;|&nbsp;</div>
            <div className="text-wrapper-27">&nbsp;{jjimed}</div>
          </div>
        </div>
      </div>
      <div className="div-item-title">
        <div className="heading-2">
          <p className="text-wrapper-28">
            최적화 블로그 체험단 마케팅을 확실하게 진<br />
            행해드립니다.
          </p>
        </div>
        <div className="div-pkg-group-list">
          <div className="heading-3">
            <p className="text-wrapper-29">단일 상품 선택 (구매를 희망하는 상품을 선택해주세요)</p>
          </div>
          
          <div className="list-2">
          { data.detail.map(function(item, index, total) {
              if (index === 0 && currentParentCode === '') {
                setCurrentParentCode(item.code)
              } 
              return (
                <>
                { item.parent_code === 'ROOT' ? (
                  <div className="item-link" style={{cursor:'pointer'}} key={item.index} onClick={(e) => {onCategory(item.code, e)} }>
                    <div className="text-wrapper-30" style={{whiteSpace:'wrap'}}>{item.name}</div>
                    <div className="span-fas">
                      <div className="symbol-10">
                        { item.code === currentParentCode ? (
                          <FaAngleUp size="17px" color="#000000" />
                        ) : (
                          <FaAngleDown size="17px" color="#000000" />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                  { item.parent_code === currentParentCode ? (
                    <div className="table-2">
                      <div className="header-row-2">
                        <div className="small-4">
                          <div className="element-4"> 블로그 체험단 10건</div>
                        </div>
                        <div className="strong-3">
                          <div className="text-wrapper-31">80,000원</div>
                        </div>
                      </div>
                      <div className="body-row-2">
                        <div className="strong-4">
                          <div className="text-wrapper-32">구매평 + 블로그 10건</div>
                        </div>
                        <div className="small-5">
                          <p className="text-wrapper-33">체험단을 모집 하여, 상품 체험하세여 만족하실거에요 체험단을 모집 하여, 상품 체험하세여 만족하실거에요 체험단을 모집 하여, 상품 체험하세여 만족하실거에요</p>
                        </div>
                      </div>
                      <div className="footer-row-2">
                        <div className="link-8" onClick={(e) =>onBasketClick(item.id, e)} style={{cursor:'pointer'}}>
                          <div className="text-wrapper-34" >장바구니 담기</div>
                        </div>
                        <div className={`link-9 ${linkClassName}`} onClick={(e) =>onPurchaseClick(item.id, e)} style={{cursor:'pointer'}}>
                          <div className="text-wrapper-35">구매</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  </>
                )}                 
                </>
              )
          })}
          </div>
          
        </div>
      </div>
    </div>
  );
};
