import React, { useState, useRef, useEffect } from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

import "./style.css";
import { useNavigate } from 'react-router-dom';
import { FooterPanel } from "./FooterPanel";
import AuthApi from "../../api/auth";
import { useAuth } from "../../contexts/auth/auth.context";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";

export const Login = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    'email': '',
    'password': ''
  });

  // Naver Auth Info
  const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID; // 발급받은 클라이언트 아이디
  const REDIRECT_URI = `${BACKEND_SERVER_BASEURL}/redirectnaver`; // Callback URL
  const STATE = "false";
  const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&state=${STATE}&redirect_uri=${REDIRECT_URI}`;

  const emailInput = useRef();
  const passwordInput = useRef();
  const navigate = useNavigate();

  // 비구조화 할당을 통해 값 추출
  const { email, password } = formData; 

  const onChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const onReset = () => {
    setFormData({
      email: '',
      password: ''
    });
    emailInput.current.focus();
  };

  const onClick = e => {
    e.preventDefault();
    AuthApi.Login(formData).then(response => {
      if(response.status == 200) {
        setProfile(response);
        navigate('/productlist');
      } else {
        alert(response.data.msg)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }
  const setProfile = (response) => {
    let user = { ...response.data.user };
    user = {
      ...user, 
      refresh: response.data.refresh,
      access: response.data.access
    };
    setUser(user);

    // 인증에 성공한 email을 localStorage에 저장
    localStorage.setItem("user", JSON.stringify({ ...user, id: '', access: '' }));
  };

  useEffect(() => {
    // 사용자 정보 초기화
    setGlobal((global) => ({
      category: 'All',
      category_name: "전체"
    }));
  }, []);
  
  const NaverLogin = () => {
    window.location.href = NAVER_AUTH_URL;
  };
  
  return (
    <div className="login">
      <div className="div-8">
        <RightMenu className="right-menu-3" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-4" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-4" />
        <NavMenu
          className="nav-menu-4"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-4" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <div className="login-text">
          <div className="login-title">
            <div className="text-wrapper-53">로그인</div>
          </div>
          <div className="login-sub-title">
            <p className="text-wrapper-54">argus1365.co.kr에 로그인 하시면 다양한 서비스를 편리하게 제공 받으실 수 있습니다.</p>
          </div>
        </div>
        <div className="form-login">
          <Input className="input-login" text="이메일" name='email' value={email} onChange={onChange} />
          <Input className="input-password" text="비밀번호" inputType='password' name='password' value={password} onChange={onChange}/>
          <Button className="button-login" divClassName="text-wrapper-57" text="로그인" onClick={onClick}/>
        </div>
        <div className="auth-area">
          <div className="forget-auth">
            <div className="text-wrapper-55">이메일/비밀번호 찾기</div>
          </div>
          <div className="registry-auth">
            <div className="text-wrapper-55">회원가입</div>
          </div>
        </div>
        <div className="external-login">
          <div className="kakao-login">
            <div className="div-9" href="https://store.gpakorea.com/member/kakao" rel="noopener noreferrer" target="_blank">카카오 로그인</div>
          </div>
          <div className="naver-login">
            <div className="div-9" rel="noopener noreferrer" onClick={NaverLogin}>네이버 로그인</div>
          </div>
          <div className="google-login">
            <div className="text-wrapper-57" href="https://accounts.google.com/o/oauth2/auth" rel="noopener noreferrer" target="_blank">Google 로그인</div>
          </div>
        </div>
        <FooterPanel />
        <KakaoService className="kakao-talk" />
        <JimmedProducts className="jimmed-products-4" />
        <ViewedProducts className="viewed-products-4" />
      </div>
    </div>
  );
};
