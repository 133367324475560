import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./style.css";
import { useAuth } from "../../contexts/auth/auth.context";
import { getProductReviews } from "../../modules/products";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaRegHeart } from 'react-icons/fa6'

export const PdBodyReview = ({ className, product_id, product_grade }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { data, loading, error } = useSelector(
    (state) => state.productReducer.productreviews
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductReviews(product_id));
  }, [dispatch, product_id]);

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;

  return (
    <div className={`PD-body-review ${className}`}>
      <div className="review-title">상품 리뷰</div>
      <div className="review-head">
        <div className="text-wrapper-18">{product_grade}</div>
        <div className="div-star">
          <div className="div-stars">
            <div className="symbol-5"><FaRegStar size="17px" color="#ffa500" /></div>
            <div className="symbol-5"><FaRegStar size="17px" color="#ffa500" /></div>
            <div className="symbol-5"><FaRegStar size="17px" color="#ffa500" /></div>
            <div className="symbol-5"><FaRegStar size="17px" color="#ffa500" /></div>
            <div className="symbol-5"><FaRegStar size="17px" color="#ffa500" /></div>
          </div>
          <div className="p-2">
            <div className="text-wrapper-19">({data.detail.length}개의 평가)</div>
          </div>
        </div>
      </div>
      <div className="review-list">

        {data.detail.map((review, index, total) => (
        <div className="item-2">
          <div className="face-frame" />
          <div className="container">
            <div className="container-2">
              <div className="text-wrapper-20">{review.created_at.substr(0,16)}</div>
            </div>
            <div className="container-3">
              <div className="symbol-6"><FaRegStar size="17px" color="#ffa500" /></div>
              <div className="symbol-6"><FaRegStar size="17px" color="#ffa500" /></div>
              <div className="symbol-6"><FaRegStar size="17px" color="#ffa500" /></div>
              <div className="symbol-6"><FaRegStar size="17px" color="#ffa500" /></div>
              <div className="symbol-7"><FaRegStar size="17px" color="#ffa500" /></div>
              <div className="vertical-divider" />
            </div>
          </div>
          <div className="container-4">
            <div className="text-wrapper-21">{review.review_text}</div>
          </div>
          <div className="container-5">
            <div className="small-3">
              <div className="text-wrapper-22">{review.user_name.substr(0,2)}******</div>
            </div>
            <div className="margin">
              <div className="container-6">
                <p className="text-wrapper-23">| 구독 : {(review.subscribe_type === 1 ? '횟수' : '월간')}</p>
              </div>
            </div>
          </div>
          <div className="container-7">
            <div className="background-border">
              <p className="text-wrapper-24">{review.review_text}</p>
            </div>
            <div className="border" />
          </div>
        </div>
        ))}


      </div>
      <div className="review-more">
        <div className="text-wrapper-25">모든 리뷰 보기</div>
      </div>
    </div>
  );
};
