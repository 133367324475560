export const FooterPanel = () => {
    return (
      <div className="footer-panel">
      <div className="footer-frame">
        <div className="company-infos">
          <div className="flexcontainer">
            <p className="text-i">
              <span className="text-wrapper-36">
                회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                제2020호-서울마포-2053호
                <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                <br />
              </span>
            </p>
            <p className="text-i">
              <span className="text-wrapper-36">
                이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
              </span>
            </p>
          </div>
        </div>
        <div className="sns-link">
          <div className="list-item-link-7">
            <div className="text-wrapper-37">블로그</div>
          </div>
          <div className="list-item-link-8">
            <div className="text-wrapper-37">유튜브</div>
          </div>
          <div className="list-item-link-9">
            <div className="text-wrapper-38">페이스북</div>
          </div>
          <div className="list-item-link-10">
            <div className="text-wrapper-39">인스타그램</div>
          </div>
        </div>
        <img className="logo-gothic" alt="Logo gothic" src="/img/logo-gothic-1.png" />
      </div>
    </div>

    );
}
