import axios from "./index";

class InquiryApi {
  static getInquiryList = async (params) => {
    const response = await axios.get("/inquirys/", { params: params });
    return response.data;
  };
  static getInquiryDetail = async (id) => {
    const response = await axios.get(`/inquirys/${id}/`);
    return response.data;
  };

  static postInquiryCreate = (formData) => {
    return axios.post(`inquirycreate/`, formData);
  };

}

export default InquiryApi;
