import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const CacheDepositMutong = () => {
  return (
    <div className="cache-deposit-mutong">
      <div className="div-30">
        <RightMenu className="right-menu-9" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-10" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-10" />
        <NavMenu
          className="nav-menu-10"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-10" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <MyPageMenu
          className="my-page-menu-instance"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-instance" />
        <div className="div-31">
          <div className="form-4">
            <div className="div-inner-point-list">
              <div className="list-2">
                <div className="label-wrapper">
                  <div className="div-32">
                    <div className="symbol-wrapper-4">
                      <div className="symbol-16"></div>
                    </div>
                    <div className="overlap-group-7">
                      <div className="text-wrapper-184">100,000캐시</div>
                      <div className="span-label">
                        <div className="text-wrapper-185">보너스 +5000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-wrapper">
                  <div className="div-32">
                    <div className="symbol-wrapper-4">
                      <div className="symbol-17"></div>
                    </div>
                    <div className="overlap-group-8">
                      <div className="text-wrapper-184">200,000캐시</div>
                      <div className="span-label">
                        <div className="text-wrapper-185">보너스 +10000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-wrapper">
                  <div className="div-32">
                    <div className="symbol-wrapper-4">
                      <div className="symbol-17"></div>
                    </div>
                    <div className="overlap-group-8">
                      <div className="text-wrapper-184">300,000캐시</div>
                      <div className="span-label">
                        <div className="text-wrapper-185">보너스 +15000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-wrapper">
                  <div className="div-32">
                    <div className="symbol-wrapper-4">
                      <div className="symbol-17"></div>
                    </div>
                    <div className="overlap-group-8">
                      <div className="text-wrapper-184">400,000캐시</div>
                      <div className="span-label">
                        <div className="text-wrapper-185">보너스 +20000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-wrapper">
                  <div className="div-32">
                    <div className="symbol-wrapper-4">
                      <div className="symbol-17"></div>
                    </div>
                    <div className="overlap-group-8">
                      <div className="text-wrapper-184">500,000캐시</div>
                      <div className="span-label">
                        <div className="text-wrapper-185">보너스 +25000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-wrapper">
                  <div className="div-32">
                    <div className="symbol-wrapper-4">
                      <div className="symbol-17"></div>
                    </div>
                    <div className="overlap-group-9">
                      <div className="text-wrapper-186">1,000,000캐시</div>
                      <div className="span-label-2">
                        <div className="text-wrapper-185">보너스 +50000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="label-wrapper">
                  <div className="div-32">
                    <div className="symbol-wrapper-4">
                      <div className="symbol-17"></div>
                    </div>
                    <div className="overlap-group-10">
                      <div className="text-wrapper-186">4,000,000캐시</div>
                      <div className="span-label-2">
                        <div className="text-wrapper-185">보너스 +200000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-32">
                  <div className="symbol-wrapper-4">
                    <div className="symbol-17"></div>
                  </div>
                  <div className="overlap-53">
                    <div className="text-wrapper-187">10,000,000캐시</div>
                    <div className="span-label-3">
                      <div className="text-wrapper-185">보너스 +500000캐시</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-inner-pay-table">
              <div className="table-10">
                <div className="body-6">
                  <div className="row-5">
                    <div className="cell-32">충전 캐시</div>
                    <div className="data-24">
                      <div className="text-wrapper-188">100,000캐시</div>
                    </div>
                  </div>
                  <div className="row-6">
                    <div className="cell-33">ㄴ 충전 혜택</div>
                    <div className="data-25">
                      <div className="text-wrapper-188">5,000캐시</div>
                    </div>
                  </div>
                  <div className="row-6">
                    <div className="cell-32">총 충전 캐시</div>
                    <div className="data-26">
                      <div className="text-wrapper-189">105,000캐시</div>
                    </div>
                  </div>
                </div>
                <div className="footer-row-2">
                  <div className="cell-34">총 결제금액</div>
                  <div className="data-27">
                    <div className="text-wrapper-190">100,000원</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-inner-pay-method">
              <div className="heading-18">
                <div className="text-wrapper-191">결제 방법</div>
              </div>
              <div className="label-4">
                <div className="span-fas-2">
                  <div className="symbol-18"></div>
                </div>
                <p className="text-wrapper-192">무통장입금 (세금계산서 미발행) + 5%서비스</p>
              </div>
              <div className="label-5">
                <div className="span-far-4">
                  <div className="symbol-19"></div>
                </div>
                <p className="text-wrapper-192">가상계좌 (세금계산서 발행) + 2%서비스</p>
              </div>
            </div>
            <div className="div-panel-dbank-2">
              <div className="div-panel-heading-4">
                <div className="text-wrapper-193">입금자 정보</div>
              </div>
              <div className="div-panel-body-11">
                <div className="div-33">
                  <p className="text-wrapper-194">
                    입력하신 입금자명과 실제 입금자명이 다른 경우 캐시가 정상적으로 적립되지 않습니다
                  </p>
                </div>
                <div className="table-11">
                  <div className="body-row-2">
                    <div className="cell-35">
                      <div className="text-wrapper-195">입금자명</div>
                    </div>
                    <div className="data-28">
                      <div className="div-input-group-10">
                        <div className="input-10" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-text-right-3">
            <div className="link-48">
              <div className="text-wrapper-196">결제하기</div>
            </div>
          </div>
        </div>
        <div className="footer-panel-9">
          <div className="footer-frame-10">
            <div className="company-infos-8">
              <div className="flexcontainer-9">
                <p className="text-i-10">
                  <span className="text-wrapper-197">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-10">
                  <span className="text-wrapper-197">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-10">
                  <span className="text-wrapper-197">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-10">
              <div className="list-item-link-43">
                <div className="text-wrapper-198">블로그</div>
              </div>
              <div className="list-item-link-44">
                <div className="text-wrapper-198">유튜브</div>
              </div>
              <div className="list-item-link-45">
                <div className="text-wrapper-199">페이스북</div>
              </div>
              <div className="list-item-link-46">
                <div className="text-wrapper-200">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-10" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-10" />
        <JimmedProducts className="jimmed-products-10" />
        <ViewedProducts className="viewed-products-10" />
      </div>
    </div>
  );
};
