import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
// import { MainBody } from "../../components/MainBody";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const MyWishList = () => {
  return (
    <div className="my-wish-list">
      <div className="div-36">
        <RightMenu className="right-menu-12" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-13" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-13" />
        <NavMenu
          className="nav-menu-13"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-13" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <MyPageMenu
          className="my-page-menu-7"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-7" />
        {/* <MainBody
          className="main-body-2"
          hasDiv={false}
          hasItemLink={false}
          href="https://store.gpakorea.com/item/view/165"
          href1="https://store.gpakorea.com/item/view/165"
          href10="https://store.gpakorea.com/item/view/165"
          href11="https://store.gpakorea.com/item/view/165"
          href12="https://store.gpakorea.com/item/view/165"
          href13="https://store.gpakorea.com/item/view/165"
          href14="https://store.gpakorea.com/item/view/165"
          href15="https://store.gpakorea.com/item/view/165"
          href16="https://store.gpakorea.com/item/view/165"
          href17="https://store.gpakorea.com/item/view/165"
          href18="https://store.gpakorea.com/item/view/165"
          href19="https://store.gpakorea.com/item/view/165"
          href2="https://store.gpakorea.com/item/view/165"
          href20="https://store.gpakorea.com/item/view/165"
          href21="https://store.gpakorea.com/item/view/20"
          href22="https://store.gpakorea.com/item/view/20"
          href23="https://store.gpakorea.com/item/view/20"
          href24="https://store.gpakorea.com/item/view/20"
          href25="https://store.gpakorea.com/item/view/20"
          href26="https://store.gpakorea.com/item/view/20"
          href27="https://store.gpakorea.com/item/view/20"
          href28="https://store.gpakorea.com/item/view/71"
          href29="https://store.gpakorea.com/item/view/71"
          href3="https://store.gpakorea.com/item/view/165"
          href30="https://store.gpakorea.com/item/view/71"
          href31="https://store.gpakorea.com/item/view/71"
          href32="https://store.gpakorea.com/item/view/71"
          href33="https://store.gpakorea.com/item/view/71"
          href34="https://store.gpakorea.com/item/view/71"
          href35="https://store.gpakorea.com/item/view/71"
          href36="https://store.gpakorea.com/item/view/71"
          href37="https://store.gpakorea.com/item/view/71"
          href38="https://store.gpakorea.com/item/view/71"
          href39="https://store.gpakorea.com/item/view/71"
          href4="https://store.gpakorea.com/item/view/165"
          href40="https://store.gpakorea.com/item/view/71"
          href41="https://store.gpakorea.com/item/view/71"
          href42="https://store.gpakorea.com/item/view/165"
          href43="https://store.gpakorea.com/item/view/165"
          href44="https://store.gpakorea.com/item/view/165"
          href45="https://store.gpakorea.com/item/view/165"
          href46="https://store.gpakorea.com/item/view/165"
          href47="https://store.gpakorea.com/item/view/165"
          href48="https://store.gpakorea.com/item/view/165"
          href5="https://store.gpakorea.com/item/view/165"
          href6="https://store.gpakorea.com/item/view/165"
          href7="https://store.gpakorea.com/item/view/165"
          href8="https://store.gpakorea.com/item/view/165"
          href9="https://store.gpakorea.com/item/view/165"
          visible={false}
        /> */}
        <div className="footer-panel-12">
          <div className="footer-frame-13">
            <div className="company-infos-11">
              <div className="flexcontainer-12">
                <p className="text-i-13">
                  <span className="text-wrapper-184">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-13">
                  <span className="text-wrapper-184">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-13">
                  <span className="text-wrapper-184">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-13">
              <div className="list-item-link-49">
                <div className="text-wrapper-185">블로그</div>
              </div>
              <div className="list-item-link-50">
                <div className="text-wrapper-185">유튜브</div>
              </div>
              <div className="list-item-link-51">
                <div className="text-wrapper-186">페이스북</div>
              </div>
              <div className="list-item-link-52">
                <div className="text-wrapper-187">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-13" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-12" />
        <JimmedProducts className="jimmed-products-13" />
        <ViewedProducts className="viewed-products-13" />
      </div>
    </div>
  );
};
