import React from "react";
import { CustomerMenu } from "../../components/CustomerMenu";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const TermsOfUse = () => {
  return (
    <div className="terms-of-use">
      <div className="div-2">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <NavMenu
          className="nav-menu-instance"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <CustomerMenu
          className="customer-menu-instance"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=10-114&mode=design"
          href1="https://store.gpakorea.com/cs/faq"
          href2="https://store.gpakorea.com/cs/voc"
          href3="https://store.gpakorea.com/cs/terms_of_use"
          href4="https://store.gpakorea.com/cs/privacy_policy"
          href5="https://store.gpakorea.com/cs/return_policy"
        />
        <div className="terms-body">
          <div className="TB">
            <div className="heading">
              <div className="text-wrapper-18">이용약관</div>
            </div>
            <div className="div-view">
              <p className="element-URL-https-store">
                제1조 (목적)
                <br />이 약관은 지피에이코리아 회사(광고대행)가 운영하는 사이트(URL : https://store.gpakorea.com 이라
                한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라
                <br />
                한다)를 이용함에 있어 지피에이코리아와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
                ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도
                <br />그 성질에 반하지 않는 한 이 약관을 준용합니다」
              </p>
              <p className="p">
                제2조 (정의)
                <br />본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                <br />
                ①“이용자”란 “해당사이트”에 접속하여 이 약관에 따라 지피에이코리아가 제공하는 서비스를 받는 회원 및
                비회원을 말합니다.
                <br />
                ②‘회원’이라 함은 지피에이코리아에 개인정보를 제공하여 회원등록을 한 자로서 정보를 지속적으로 제공받으며
                지피에이코리아가 제공하는 서비스를 계속적으로
                <br />
                이용할 수 있는 자를 말합니다.
                <br />
                ③‘비회원’이라 함은 회원에 가입하지 않고 지피에이코리아가 제공하는 서비스를 이용하는 자를 말합니다.
              </p>
              <p className="p">
                제3조 (약관 등의 명시와 설명 및 개정)
                <br />
                ①지피에이코리아는 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는
                곳의 주소를 포함), 전화번호ㆍ모사전송번호ㆍ전자
                <br />
                우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 사이트의
                초기 서비스화면(전면)에 게시합니다. 다만, 약관
                <br />의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
                <br />
                ②지피에이코리아는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 환불조건 등과 같은 중요한
                내용을 이용자가 이해할 수 있도록 별도의 연결화
                <br />면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
                <br />
                ③지피에이코리아는 전자상거래등에서의소비자보호에관한법률, 약관의규제에관한법률, 전자거래기본법,
                전자서명법, 정보통신망이용촉진등에관한법률, 방문판
                <br />
                매등에관한법률, 소비자보호법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                <br />
                ④지피에이코리아는 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 사이트
                초기화면에 그 적용일자 7일이전부터 적용일자 전일까지
                <br />
                공지합니다.
                <br />
                다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고
                공지합니다. 이 경우 지피에이코리아는 개정전 내용과 개정
                <br />후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
                <br />
                ⑤지피에이코리아는 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그
                이전에 이미 체결된 계약에 대해서는 개정전의 약<br />
                관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을
                제3항에 의한 개정약관의 공지기간내에 지피에이코
                <br />
                리아에 송신하여 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
                <br />
                ⑥이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의소비자보호에관한법률,
                약관의규제등에관한법률, 공정거래위원회가 정하는
                <br />
                전자상거래등에서의소비자보호지침 및 관계법령 또는 상관례에 따릅니다.
              </p>
              <p className="p">
                제4조 (서비스의 제공 및 변경)
                <br />
                ①지피에이코리아는 다음과 같은 업무를 수행합니다.
                <br />
                1. 마케팅상품에 대한 정보 제공 및 구매계약의 체결
                <br />
                2. 기타 지피에이코리아가 정하는 업무
                <br />
                지피에이코리아는 마케팅상품의 서비스 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 마케팅상품을
                변경할 수 있습니다. 이 경우에는 내용 및 제공일자를
                <br />
                명시하여 현재의 마케팅상품 내용을 게시한 곳에 즉시 공지합니다.
                <br />
                ②지피에이코리아가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 마케팅상품의 서비스 변경 등의 사유가
                발생할 경우에는 그 사유를 이용자에게 통지 가<br />
                능한 주소로 즉시 통지합니다.
                <br />
                ③전항의 경우 지피에이코리아는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, 지피에이코리아가 고의
                또는 과실이 없음을 입증하는 경우에는 그러하지 아<br />
                니합니다.
              </p>
              <p className="p">
                제5조 (서비스의 중단)
                <br />
                ①지피에이코리아는 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한
                경우에는 서비스의 제공을 일시적으로 중단할 수 있습니
                <br />
                다.
                <br />
                ②지피에이코리아는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은
                손해에 대하여 배상합니다. 단, 지피에이코리아가
                <br />
                고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
                <br />
                ③사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는
                지피에이코리아는 제8조에 정한 방법으로 이용자에게 통지하
                <br />고 당초 제시한 조건에 따라 소비자에게 보상합니다. 다만, 지피에이코리아가 보상기준 등을 고지하지
                아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 지<br />
                피에이코리아에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
              </p>
              <p className="p">
                제6조 (회원가입)
                <br />
                ①이용자는 지피에이코리아가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를
                함으로서 회원가입을 신청합니다.
                <br />
                ②지피에이코리아는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한
                회원으로 등록합니다.
                <br />
                1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에
                의한 회원자격 상실 후 3년이 경과한 자로서 지피에
                <br />
                이코리아의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
                <br />
                2. 등록 내용에 허위, 기재 누락, 오기가 있는 경우
                <br />
                3. 기타 회원으로 등록하는 것이 지피에이코리아의 기술상 현저히 지장이 있다고 판단되는 경우
                <br />
                ③회원가입계약의 성립시기는 지피에이코리아의 승낙이 회원에게 도달한 시점으로 합니다.
                <br />
                ④회원은 제15조제1항에 의한 등록사항에 변경이 있는 경우, 즉시 전자우편 기타 방법으로 지피에이코리아에
                대하여 그 변경사항을 알려야 합니다.
              </p>
              <p className="p">
                제7조 (회원 탈퇴 및 자격 상실 등)
                <br />
                ①회원은 지피에이코리아에 언제든지 탈퇴를 요청할 수 있으며 지피에이코리아는 즉시 회원탈퇴를 처리합니다.
                <br />
                ②회원이 다음 각호의 사유에 해당하는 경우, 지피에이코리아는 회원자격을 제한 및 정지시킬 수 있습니다.
                <br />
                1. 가입 신청시에 허위 내용을 등록한 경우
                <br />
                2. 다른 사람의 지피에이코리아 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
                <br />
                3. 지피에이코리아를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
                <br />
                ③지피에이코리아가 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일이내에 그 사유가
                시정되지 아니하는 경우 회원자격을 상실시킬 수<br />
                있습니다.
                <br />
                ④지피에이코리아가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고,
                회원등록 말소 전에 최소한 30일 이상의 기간을
                <br />
                정하여 소명할 기회를 부여합니다.
              </p>
              <p className="p">
                제8조 (회원에 대한 통지)
                <br />
                ①지피에이코리아가 회원에 대한 통지를 하는 경우, 회원이 지피에이코리아와 미리 약정하여 지정한 전자우편
                주소로 할 수 있습니다.
                <br />
                ②지피에이코리아는 불특정다수 회원에 대한 통지의 경우 1주일이상 게시판에 게시함으로서 개별 통지에 갈음할
                수 있습니다. 다만, 회원 본인의 거래와 관련하여
                <br />
                중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
              </p>
              <p className="p">
                제9조 (구매신청)
                <br />
                지피에이코리아 이용자는 사이트에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며 이용자가
                구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하
                <br />
                여야 합니다. 단, 회원인 경우 제2호 내지 제4호의 적용을 제외할 수 있습니다.
                <br />
                1. 마케팅상품의 검색 및 선택
                <br />
                2. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
                <br />
                3. 약관내용, 청약철회권이 제한되는 서비스의 비용부담과 관련한 내용에 대한 확인
                <br />
                4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)
                <br />
                5. 마케팅상품의 구매신청 및 이에 관한 확인 또는 지피에이코리아의 확인에 대한 동의
                <br />
                6. 결제방법의 선택
              </p>
              <p className="p">
                제10조 (계약의 성립)
                <br />
                ①지피에이코리아는 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다. 다만,
                미성년자와 계약을 체결하는 경우에는 법정대리인
                <br />의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야
                합니다.
                <br />
                1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
                <br />
                3. 기타 구매신청에 승낙하는 것이 지피에이코리아 기술상 현저히 지장이 있다고 판단하는 경우
                <br />
                ②지피에이코리아의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로
                봅니다.
                <br />
                ③지피에이코리아의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정
                취소 등에 관한 정보 등을 포함하여야 합니다.
              </p>
              <p className="p">
                제11조 (지급방법)
                <br />
                지피에이코리아 사이트에서 구매한 마케팅상품대한 대금지급방법은 다음 각호의 방법 중 가용한 방법으로 할 수
                있습니다. 단, 지피에이코리아는 이용자의 지급방
                <br />
                법에 대하여 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.
                <br />
                1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
                <br />
                2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
                <br />
                3. 온라인무통장입금
                <br />
                제13조 (마케팅상품의 공급)
                <br />
                ①지피에이코리아는 이용자와 마케팅상품의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한
                날부터 업무진행을 할 수 있도록 기타의 필요한 조치
                <br />를 취합니다. 이때 지피에이코리아은 이용자가 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
              </p>
              <p className="p">
                제14조 (환급)
                <br />
                지피에이코리아는 이용자가 마케팅 상품 등이 변경 등의 사유로 제공을 할 수 없을 때에는 지체없이 그 사유를
                이용자에게 통지하고 사전에 대금을 받은 경우에는
                <br />
                대금을 받은 날부터 2영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
              </p>
              <p className="p">
                제15조 (청약철회 등)
                <br />
                ①지피에이코리아와 상품의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날부터 24시간
                이내에는 청약의 철회를 할 수 있습니다.
                <br />
                ②제2항제2호 내지 제4호의 경우에 지피에이코리아가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알
                수 있는 곳에 명기하거나 시용 상품을 제공하는 등<br />의 조치를 하지 않았다면 이용자의 청약철회등이
                제한되지 않습니다.
                <br />
                ③이용자는 제1항 및 제2항의 규정에 불구하고 상품의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게
                이행된 때에는 당해 마케팅서비스를 공급받은 날부
                <br />터 7일 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
              </p>
              <p className="p">
                제16조 (청약철회 등의 효과)
                <br />
                ①이용자로부터 상품 등을 반환받은 경우 3영업일 이내에 이미 지급받은 상품등의 대금을 환급합니다. 이 경우
                지피에이코리아가 이용자에게 상품등의 환급을 지<br />
                연한 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를
                지급합니다.
                <br />
                ②지피에이코리아는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 상품의
                대금을 지급한 때에는 지체없이 당해 결제수단을 제<br />
                공한 사업자로 하여금 상품 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
                <br />
                ③지피에이코리아는 이용자에게 청약철회등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 상품의
                내용이 표시·광고 내용과 다르거나 계약내용과 다<br />
                르게 이행되어 청약철회등을 하는 경우 반환에 필요한 비용은 지피에이코리아가 부담합니다.
              </p>
              <p className="p">
                제17조 (개인정보보호)
                <br />
                ①지피에이코리아는 이용자의 정보수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다. 다음 사항을
                필수사항으로 하며 그 외 사항은 선택사항으로 합니
                <br />
                다.
                <br />
                1. 성명
                <br />
                2. 주민등록번호(회원의 경우) 또는 외국인등록번호
                <br />
                3. 주소
                <br />
                4. 전화번호
                <br />
                5. 희망ID(회원의 경우)
                <br />
                6. 비밀번호(회원의 경우)
                <br />
                7. 전자우편주소(또는 이동전화번호)
                <br />
                ②지피에이코리아가 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를
                받습니다.
                <br />
                ③제공된 개인정보는 당해 이용자의 동의없이 목적외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든
                책임은 지피에이코리아가 집니다. 다만, 다음의 경<br />
                우에는 예외로 합니다.
                <br />
                1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는
                경우
                <br />
                2. 상품등의 거래에 따른 대금정산을 위하여 필요한 경우
                <br />
                3. 도용방지를 위하여 본인확인에 필요한 경우
                <br />
                4. 법률의 규정 또는 법률에 의하여 필요한 불가피한 사유가 있는 경우
                <br />
                ④지피에이코리아가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의
                신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수<br />
                집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등
                정보통신망이용촉진등에관한법률 제22조제2항이 규정한
                <br />
                사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
                <br />
                ⑤이용자는 언제든지 지피에이코리아가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수
                있으며 이에 대해 지체없이 필요한 조치를 취할 의무를
                <br />
                집니다. 이용자가 오류의 정정을 요구한 경우에는 그 오류를 정정할 때까지 당해 개인정보를 이용하지
                않습니다.
                <br />
                ⑥지피에이코리아는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을
                포함한 이용자의 개인정보의 분실, 도난, 유출, 변조
                <br />
                등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
                <br />
                ⑦지피에이코리아 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을
                달성한 때에는 당해 개인정보를 지체없이 파기합니다.
              </p>
              <p className="p">
                제18조 (지피에이코리아의 의무)
                <br />
                ①지피에이코리아는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에
                따라 지속적이고, 안정적으로 재화 · 용역을 제공
                <br />
                하는데 최선을 다하여야 합니다.
                <br />
                ②지피에이코리아는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보
                포함)보호를 위한 보안 시스템을 갖추어야 합니다.
                <br />
                ③지피에이코리아가 상품이나 용역에 대하여 「표시 · 광고의공정화에관한법률」 제3조 소정의 부당한
                표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이<br />를 배상할 책임을 집니다.
                <br />
                ④지피에이코리아는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
              </p>
              <p className="p">
                제19조 (회원의 ID 및 비밀번호에 대한 의무)
                <br />
                ①제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
                <br />
                ②회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
                <br />
                ③회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로
                지피에이코리아에 통보하고 안내가 있는 경우에는 그에 따라야
                <br />
                합니다.
              </p>
              <p className="p">
                제20조 (이용자의 의무)
                <br />
                이용자는 다음 행위를 하여서는 안됩니다.
                <br />
                1. 신청 또는 변경시 허위 내용의 등록
                <br />
                2. 타인의 정보 도용
                <br />
                3. 지피에이코리아에 게시된 정보의 변경
                <br />
                4. 지피에이코리아가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
                <br />
                5. 지피에이코리아 기타 제3자의 저작권 등 지적재산권에 대한 침해
                <br />
                6. 지피에이코리아 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
                <br />
                7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 지피에이코리아에 공개 또는
                게시하는 행위
              </p>
              <p className="p">
                제22조 (저작권의 귀속 및 이용제한)
                <br />
                ①지피에이코리아가 작성한 저작물에 대한 저작권 기타 지적재산권은 지피에이코리아에 귀속합니다.
                <br />
                ②이용자는 지피에이코리아를 이용함으로써 얻은 정보 중 지피에이코리아에게 지적재산권이 귀속된 정보를
                지피에이코리아의 사전 승낙없이 복제, 송신, 출판, 배<br />
                포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
                <br />
                ③지피에이코리아는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야
                합니다.
                <br />
                제23조(분쟁해결)
                <br />
                ①지피에이코리아는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여
                피해보상처리기구를 설치·운영합니다.
                <br />
                ②지피에이코리아는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한
                처리가 곤란한 경우에는 이용자에게 그 사유와 처<br />
                리일정을 즉시 통보해 드립니다.
                <br />
                ③지피에이코리아와 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는
                공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조
                <br />
                정기관의 조정에 따를 수 있습니다.
              </p>
              <p className="p">
                제24조(재판권 및 준거법)
                <br />
                ①지피에이코리아와 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고,
                주소가 없는 경우에는 거소를 관할하는 지방법원의
                <br />
                전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는
                민사소송법상의 관할법원에 제기합니다.
                <br />
                ②지피에이코리아와 이용자간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
              </p>
            </div>
          </div>
        </div>
        <div className="footer-panel">
          <div className="footer-frame">
            <div className="company-infos">
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-19">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-19">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-19">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link">
              <div className="list-item-link">
                <div className="text-wrapper-20">블로그</div>
              </div>
              <div className="list-item-link-2">
                <div className="text-wrapper-20">유튜브</div>
              </div>
              <div className="list-item-link-3">
                <div className="text-wrapper-21">페이스북</div>
              </div>
              <div className="list-item-link-4">
                <div className="text-wrapper-22">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-instance" />
        <JimmedProducts className="jimmed-products-instance" />
        <ViewedProducts className="viewed-products-instance" />
      </div>
    </div>
  );
};
