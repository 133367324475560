import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const CacheDeposit = () => {
  return (
    <div className="cache-deposit">
      <div className="div-34">
        <RightMenu className="right-menu-10" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-11" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-11" />
        <NavMenu
          className="nav-menu-11"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-11" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <MyPageMenu
          className="my-page-menu-2"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-2" />
        <div className="div-35">
          <div className="form-5">
            <div className="list-wrapper">
              <div className="div-36">
                <div className="item-2">
                  <div className="div-37">
                    <div className="symbol-wrapper-5">
                      <div className="symbol-20"></div>
                    </div>
                    <div className="overlap-group-11">
                      <div className="text-wrapper-201">100,000캐시</div>
                      <div className="span-label-4">
                        <div className="text-wrapper-202">보너스 +2000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-2">
                  <div className="div-37">
                    <div className="symbol-wrapper-5">
                      <div className="symbol-21"></div>
                    </div>
                    <div className="overlap-group-11">
                      <div className="text-wrapper-201">200,000캐시</div>
                      <div className="span-label-4">
                        <div className="text-wrapper-202">보너스 +4000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-2">
                  <div className="div-37">
                    <div className="symbol-wrapper-5">
                      <div className="symbol-21"></div>
                    </div>
                    <div className="overlap-group-11">
                      <div className="text-wrapper-201">300,000캐시</div>
                      <div className="span-label-4">
                        <div className="text-wrapper-202">보너스 +6000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-2">
                  <div className="div-37">
                    <div className="symbol-wrapper-5">
                      <div className="symbol-21"></div>
                    </div>
                    <div className="overlap-group-11">
                      <div className="text-wrapper-201">400,000캐시</div>
                      <div className="span-label-4">
                        <div className="text-wrapper-202">보너스 +8000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-2">
                  <div className="div-37">
                    <div className="symbol-wrapper-5">
                      <div className="symbol-21"></div>
                    </div>
                    <div className="overlap-group-12">
                      <div className="text-wrapper-201">500,000캐시</div>
                      <div className="span-label-4">
                        <div className="text-wrapper-202">보너스 +10000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-2">
                  <div className="div-37">
                    <div className="symbol-wrapper-5">
                      <div className="symbol-21"></div>
                    </div>
                    <div className="overlap-group-13">
                      <div className="text-wrapper-203">1,000,000캐시</div>
                      <div className="span-label-5">
                        <div className="text-wrapper-202">보너스 +20000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item-2">
                  <div className="div-37">
                    <div className="symbol-wrapper-5">
                      <div className="symbol-21"></div>
                    </div>
                    <div className="overlap-group-13">
                      <div className="text-wrapper-203">4,000,000캐시</div>
                      <div className="span-label-5">
                        <div className="text-wrapper-202">보너스 +80000캐시</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="div-37">
                  <div className="symbol-wrapper-5">
                    <div className="symbol-21"></div>
                  </div>
                  <div className="overlap-54">
                    <div className="text-wrapper-204">10,000,000캐시</div>
                    <div className="span-label-6">
                      <div className="text-wrapper-202">보너스 +200000캐시</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-inner-pay-table-2">
              <div className="table-12">
                <div className="body-7">
                  <div className="row-7">
                    <div className="cell-36">충전 캐시</div>
                    <div className="data-29">
                      <div className="text-wrapper-205">100,000캐시</div>
                    </div>
                  </div>
                  <div className="row-8">
                    <div className="cell-37">ㄴ 충전 혜택</div>
                    <div className="data-30">
                      <div className="text-wrapper-205">2,000캐시</div>
                    </div>
                  </div>
                  <div className="row-8">
                    <div className="cell-36">총 충전 캐시</div>
                    <div className="data-31">
                      <div className="text-wrapper-206">102,000캐시</div>
                    </div>
                  </div>
                  <div className="row-9">
                    <div className="cell-38">부가세</div>
                    <div className="data-32">
                      <div className="div-wrapper-7">
                        <div className="text-wrapper-205">10,000원</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-row-3">
                  <div className="cell-39">총 결제금액</div>
                  <div className="data-33">
                    <div className="text-wrapper-207">110,000원</div>
                    <div className="div-wrapper-7">
                      <div className="text-wrapper-208">(VAT 포함)</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div-inner-pay-method-2">
              <div className="heading-19">
                <div className="text-wrapper-209">결제 방법</div>
              </div>
              <div className="label-6">
                <div className="span-far-5">
                  <div className="symbol-22"></div>
                </div>
                <p className="text-wrapper-210">무통장입금 (세금계산서 미발행) + 5%서비스</p>
              </div>
              <div className="label-7">
                <div className="span-fas-3">
                  <div className="symbol-23"></div>
                </div>
                <p className="text-wrapper-210">가상계좌 (세금계산서 발행) + 2%서비스</p>
              </div>
            </div>
            <div className="div-inner-taxinvoice">
              <div className="heading-20">
                <div className="text-wrapper-211">세금계산서</div>
                <div className="small-8">
                  <p className="text-wrapper-212">(세금계산서 미발행 시 관리자에게 따로 연락주세요.)</p>
                </div>
              </div>
              <div className="table-13">
                <div className="body-8">
                  <div className="row-10">
                    <div className="cell-40">
                      <div className="text-wrapper-213">사업자등록번호</div>
                    </div>
                    <div className="data-34">
                      <div className="div-input-group-11">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-40">
                      <div className="text-wrapper-213">상호</div>
                    </div>
                    <div className="data-35">
                      <div className="div-input-group-12">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-40">
                      <div className="text-wrapper-213">대표자명</div>
                    </div>
                    <div className="data-35">
                      <div className="div-input-group-12">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-40">
                      <div className="text-wrapper-213">업태</div>
                    </div>
                    <div className="data-35">
                      <div className="div-input-group-12">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-40">
                      <div className="text-wrapper-213">종목</div>
                    </div>
                    <div className="data-35">
                      <div className="div-input-group-12">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-41">
                      <div className="text-wrapper-213">사업장주소</div>
                    </div>
                    <div className="data-36">
                      <div className="div-input-group-13">
                        <div className="input-12">
                          <div className="div-36">
                            <div className="text-wrapper-214">주소</div>
                          </div>
                        </div>
                        <div className="span-input-group-btn-5">
                          <div className="link-49">
                            <p className="symbol-24">
                              <span className="text-wrapper-215"></span>
                              <span className="text-wrapper-216">주소 검색</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="div-input-group-12">
                        <div className="input-13">
                          <div className="div-36">
                            <div className="text-wrapper-217">상세 주소</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-40">
                      <div className="text-wrapper-213">담당자명</div>
                    </div>
                    <div className="data-35">
                      <div className="div-input-group-12">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-40">
                      <div className="text-wrapper-213">담당자 연락처</div>
                    </div>
                    <div className="data-35">
                      <div className="div-input-group-12">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                  <div className="row-10">
                    <div className="cell-42">
                      <div className="text-wrapper-213">담당자 이메일</div>
                    </div>
                    <div className="data-37">
                      <div className="div-input-group-12">
                        <div className="input-11" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-text-right-4">
            <div className="link-50">
              <div className="text-wrapper-218">결제하기</div>
            </div>
          </div>
        </div>
        <div className="footer-panel-10">
          <div className="footer-frame-11">
            <div className="company-infos-9">
              <div className="flexcontainer-10">
                <p className="text-i-11">
                  <span className="text-wrapper-219">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-11">
                  <span className="text-wrapper-219">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-11">
                  <span className="text-wrapper-219">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-11">
              <div className="list-item-link-47">
                <div className="text-wrapper-220">블로그</div>
              </div>
              <div className="list-item-link-48">
                <div className="text-wrapper-220">유튜브</div>
              </div>
              <div className="list-item-link-49">
                <div className="text-wrapper-221">페이스북</div>
              </div>
              <div className="list-item-link-50">
                <div className="text-wrapper-222">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-11" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-11" />
        <JimmedProducts className="jimmed-products-11" />
        <ViewedProducts className="viewed-products-11" />
      </div>
    </div>
  );
};
