import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style.css";

export const BodySearch = ({ className, inputType = "text", pageInfo, onSearchSubmit  }) => {
  const { totalCount, currPage, next, prev } = pageInfo;             // 비구조화 할당
  const [formData, setFormData] = useState({
    'search': ''
  });

  const { search } = formData; 

  const onChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });

  };

  const onClick = e => {
    e.preventDefault();
    if (search != "") {
      onSearchSubmit(search);
    } 
  } 

  return (
    <div className={`body-search ${className}`}>
      <div className="options">
        <div className="text-wrapper-13">제목+내용</div>
      </div>
      <input className="input-2" type={inputType} name='search' value={search} onChange={onChange} />
      <button className="button" disabled={totalCount > 0 ? false : true} onClick={onClick}>
        <div className="text-wrapper-14">검색</div>
      </button>
    </div>
  );
};

BodySearch.propTypes = {
  inputType: PropTypes.string,
};


