import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductList } from "../components/ProductList";
import { getProductList, clearProductList } from "../modules/products";
import { useAuth } from "../contexts/auth/auth.context";

export const ProductListContainer = ({categoryParam = 'All', orderParam = 'best_desc'}) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const [ currPage, setCurrPage ] = useState(1);
  const [ category, setCategory ] = useState('');
  const [ displayOrder, setDisplayOrder ] = useState('best_desc');
  const [ searchWord, setSearchWord ] = useState('');

  const { data, loading, error} = useSelector((state) => state.productReducer.productlist);
  const dispatch = useDispatch();

  const onDisplayOrder = (orderParm) => {
    setDisplayOrder(orderParm);
  }

  const onSearchSubmit = (searchParm) => {
    setSearchWord(searchParm);
  }

  // 컴포넌트 마운트 후 목록 요청
  useEffect(() => {
    dispatch(getProductList(currPage, category, searchWord, displayOrder));
  }, [dispatch, currPage, category, searchWord, displayOrder]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  return (
    <ProductList categoryParam={category} searchWordParam={searchWord} displayOrderParam={displayOrder} productsParam={data} onDisplayOrder={onDisplayOrder} onSearchSubmit={onSearchSubmit}/>
  );
}

