import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BulletinList } from "../components/BulletinList/BulletinList";
import { getBulletinList } from "../modules/bulletins";

export const BulletinListContainer = () => {
  const [ currPage, setCurrPage ] = useState(1);
  const [ search, setSearch ] = useState("");
  const { data, loading, error } = useSelector(
    (state) => state.bulletinReducer.bulletinlist
  );
  const dispatch = useDispatch();

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getBulletinList(currPage, search));
  }, [dispatch, currPage, search]);

  const onPageSubmit = (paramNumber) => {
    setCurrPage(paramNumber);
  }

  const onSearchSubmit = (paramSearch) => {
    if (paramSearch != "") {
      setCurrPage(1);
      setSearch(paramSearch);
    }
  }

  if (loading && !data) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;
  return (
    <BulletinList 
      bulletins={data.results} 
      pageInfo={{totalCount: data.count, currPage: currPage, next:data.next, prev:data.previous}}
      onPageSubmit={onPageSubmit} 
      onSearchSubmit={onSearchSubmit}
    />
  );
}

