import { useState, useCallback } from "react";

function useForm(initialForm) {
    const [form, setForm] = useState(initialForm);
    //change
    const onChange = useCallback((e) => {
        const {name, value} = e.target;
        setForm( form => ({
            ...form,
            [name]: value
        }));
    },[]);

    // reset
    const onReset = useCallback(() =>{
        setForm(initialForm)
    },[initialForm])
    return [form, onChange, onReset];
}
export default useForm;