import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const MyInviteLink = () => {
  return (
    <div className="my-invite-link">
      <div className="div-11">
        <RightMenu className="right-menu-5" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-6" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-6" />
        <NavMenu
          className="nav-menu-6"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-6" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <MyPageMenu
          className="my-page-menu-3"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-3" />
        <div className="div-12">
          <div className="div-referer-url">
            <div className="div-dropdown">
              <div
                className="button-4"
                href="https://store.gpakorea.com/page.html"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="overlap-group-6">
                  <div
                    className="text-wrapper-69"
                    href="https://store.gpakorea.com/page.html"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    초대링크선택
                  </div>
                  <div className="span-caret" />
                </div>
              </div>
              <div className="text-wrapper-70">가입페이지</div>
            </div>
            <div className="div-input-group-2">
              <div className="input-8">
                <div className="div-13">
                  <div className="text-wrapper-71">https://store.gpakorea.com/w/K414C162</div>
                </div>
              </div>
              <div className="span-input-group-btn">
                <div className="link-10">
                  <div className="text-wrapper-72">URL 복사</div>
                </div>
              </div>
            </div>
          </div>
          <div className="div-box-empty-2">
            <div className="text-wrapper-73">추천인이 없습니다.</div>
          </div>
        </div>
        <div className="footer-panel-5">
          <div className="footer-frame-6">
            <div className="company-infos-4">
              <div className="flexcontainer-5">
                <p className="text-i-6">
                  <span className="text-wrapper-74">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-6">
                  <span className="text-wrapper-74">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-6">
                  <span className="text-wrapper-74">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-6">
              <div className="list-item-link-21">
                <div className="text-wrapper-75">블로그</div>
              </div>
              <div className="list-item-link-22">
                <div className="text-wrapper-75">유튜브</div>
              </div>
              <div className="list-item-link-23">
                <div className="text-wrapper-76">페이스북</div>
              </div>
              <div className="list-item-link-24">
                <div className="text-wrapper-77">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-6" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-5" />
        <JimmedProducts className="jimmed-products-6" />
        <ViewedProducts className="viewed-products-6" />
      </div>
    </div>
  );
};
