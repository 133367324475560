import Axios from "axios";
import { ELASTIC_SERVER_BASEURL } from "../config/constant";

const axios = Axios.create({
  baseURL: `${ELASTIC_SERVER_BASEURL}/`,
  headers: { 
    "Content-Type": "application/json", 
  },
  auth : {
    username: "elastic",
    password: "argus05!",
  }
});
axios.interceptors.request.use(
  (config) => {
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);
axios.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    return Promise.reject(error);
  }
);
export default axios;

