/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import "./style.css";

export const KakaoService = ({ className }) => {
  return (
    <div className={`kakao-service ${className}`}>
      <div className="chat-box">
        <div className="span-ico-talk-banner" />
        <div className="emphasis">
          <div className="text-wrapper-8">
            리팡아거스
            <br />
            서비스센터
          </div>
        </div>
        <div className="strong">
          <div className="text-wrapper-9">문의하기</div>
          <img className="after" alt="After" src="/img/after.png" />
        </div>
      </div>
    </div>
  );
};
