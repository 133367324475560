import * as bulletinApi from "../api/bulletins";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */

// 여러개 조회하기
const GET_BULLETINLIST = "GET_BULLETINLIST";
const GET_BULLETINLIST_SUCCESS = "GET_BULLETINLIST_SUCCESS";
const GET_BULLETINLIST_ERROR = "GET_BULLETINLIST_ERROR";

// 상품 항목 조회하기
const GET_BULLETINDETAIL = "GET_BULLETINDETAIL";
const GET_BULLETINDETAIL_SUCCESS = "GET_BULLETINDETAIL_SUCCESS";
const GET_BULLETINDETAIL_ERROR = "GET_BULLETINDETAIL_ERROR";

export const getBulletinList = (pageNumber, search) => ({
  type: GET_BULLETINLIST,
  payload: { page: pageNumber, search: search },
  meta: pageNumber,
});

export const getBulletinDetail = (id) => ({
  type: GET_BULLETINDETAIL,
  payload: id,
  meta: id,
});

const getBulletinListSaga = createPromiseSagaList(
  GET_BULLETINLIST,
  bulletinApi.getBulletinList
);
const getBulletinDetailSaga = createPromiseSagaDetail(
  GET_BULLETINDETAIL,
  bulletinApi.getBulletinDetail
);

// 사가들을 합치기
export function* bulletinSaga() {
  yield takeEvery(GET_BULLETINLIST, getBulletinListSaga);
  yield takeEvery(GET_BULLETINDETAIL, getBulletinDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  bulletinlist: reducerUtils.initial(),
  bulletindetail: reducerUtils.initial(),
};

export default function bulletinReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BULLETINLIST:
    case GET_BULLETINLIST_SUCCESS:
    case GET_BULLETINLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_BULLETINLIST,
        "bulletinlist",
        true
      );
      return reducerList(state, action);

    case GET_BULLETINDETAIL:
    case GET_BULLETINDETAIL_SUCCESS:
    case GET_BULLETINDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_BULLETINDETAIL,
        "bulletindetail",
        true
      );
      return reducerDetail(state, action);
    default:
      return state;
  }
}
