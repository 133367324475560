/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const FooterPanel = ({
  className, bodyBottom, 
  logoGothic = "/img/logo-gothic-1-2.png",
  footerFrameClassName,
  logoGothicClassName,
  text = "(주)리팡아거스<br/>주소 : 서울특별시 마포구 마포대로 45 일진빌딩 5층<br/>전화번호 : 02-6959-7703, 이메일 : yimds@argus1365.com<br/>Copyright 2024 (주)리팡아거스 All Right Reserved.",
  companyInfosClassName,
}) => {
  return (
    <div className={`footer-panel ${className}`}  style={{top:`${bodyBottom}px`}}>
      <div className={`footer-frame ${footerFrameClassName}`}>
        <img className={`logo-gothic ${logoGothicClassName}`} alt="Logo gothic" src={logoGothic} />
        <div className={`company-infos ${companyInfosClassName}`}>
          <div className="descriptions-details">
            {text.split("<br/>").map((txt, index, total) => (
              <div key={index}>{txt}<br/></div>
            ))}
          </div>
        </div>
        <div className="sns-link">
          <div className="list-item-link">
            <div className="text-wrapper">블로그</div>
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper">유튜브</div>
          </div>
          <div className="div">
            <div className="text-wrapper-2">페이스북</div>
          </div>
          <div className="list-item-link-2">
            <div className="text-wrapper-3">인스타그램</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FooterPanel.propTypes = {
  logoGothic: PropTypes.string,
  text: PropTypes.string,
};
