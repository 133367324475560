import React from "react";
import { CustomerMenu } from "../../components/CustomerMenu";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { Link } from "react-router-dom";

function FaqDetail({faq}) {
  return (
    <div className="faq-detail">
      <div className="div-2">
        <RightMenu className="right-menu-instance" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-instance" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-instance" />
        <NavMenu
          className="nav-menu-instance"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree
          className="link-tree-instance"
          href="https://store.gpakorea.com/"
          href1="https://store.gpakorea.com/"
        />
        <div className="faq-detail-body">
          <div className="faq-detail-body-2">
            <div className="span-bar" />
            <div className="heading">FAQ</div>
          </div>
          <div className="faq-detail-body-3">
            <div className="div-3">
              <p className="p">[완료] 2023년 12월 15일(금) 아이피교체 및 서비스점검 안내</p>
            </div>
            <div className="div-4">
              <div className="table-body-row-data">카테고리 : { faq.category }</div>
              <div className="table-body-row-data-2">작성일 : { faq.created_at.substr(0,10) }</div>
              <div className="table-body-row-data-3">조회 : { faq.view.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }</div>
            </div>
          </div>
          <div className="faq-detail-body-4">
            <div className="table-body-row-data-wrapper">
              {/* <p className="table-body-row-data-4"> */}
                <div dangerouslySetInnerHTML={{__html: faq.content}}></div>
              {/* </p> */}
            </div>
          </div>
          <div className="faq-detail-body-back">
            <Link to={`/faqlist`}>            
              <div className="link-4">
                <div className="text-wrapper-22">목록</div>
              </div>
            </Link>
          </div>
        </div>
        <CustomerMenu
          className="customer-menu-instance"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=10-114&mode=design"
          href1="https://store.gpakorea.com/cs/faq"
          href2="https://store.gpakorea.com/cs/voc"
          href3="https://store.gpakorea.com/cs/terms_of_use"
          href4="https://store.gpakorea.com/cs/privacy_policy"
          href5="https://store.gpakorea.com/cs/return_policy"
        />
        <KakaoService className="kakao-service-instance" />
        <ViewedProducts className="viewed-products-instance" />
        <JimmedProducts className="jimmed-products-instance" />
        <div className="footer-panel">
          <div className="footer-frame">
            <div className="company-infos">
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-23">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-23">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-23">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link">
              <div className="list-item-link">
                <div className="text-wrapper-24">블로그</div>
              </div>
              <div className="list-item-link-2">
                <div className="text-wrapper-24">유튜브</div>
              </div>
              <div className="list-item-link-3">
                <div className="text-wrapper-25">페이스북</div>
              </div>
              <div className="list-item-link-4">
                <div className="text-wrapper-26">인스타그램</div>
              </div>
            </div>
            <img
              className="logo-gothic"
              alt="Logo gothic"
              src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/661683d093f5a561eab68f80/img/mainlogo-frame-2x-1.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FaqDetail;