import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DeterminedList } from "../components/DeterminedList";
import { getDeterminedList, clearDeterminedList } from "../modules/determineds";
import { useAuth } from "../contexts/auth/auth.context";

export const DeterminedListContainer = ({categoryParam = '', orderParam = ''}) => {
  const { user, setUser, global, setGlobal } = useAuth();

  const [ currPage, setCurrPage ] = useState(1);
  const [ category, setCategory ] = useState('');
  const [ displayOrder, setDisplayOrder ] = useState('best_desc');
  const [ searchWord, setSearchWord ] = useState('');

  const { data, loading, error} = useSelector((state) => state.determinedReducer.determinedlist);
  const dispatch = useDispatch();

  const onDisplayOrder = (orderParm) => {
    setDisplayOrder(orderParm);
  }

  const onSearchSubmit = (searchParm) => {
    setSearchWord(searchParm);
  }

  // 컴포넌트 마운트 후 목록 요청
  useEffect(() => {
    dispatch(getDeterminedList(currPage, category, searchWord, displayOrder));
  }, [dispatch, currPage, category, searchWord, displayOrder]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;

  return (
    <DeterminedList categoryParam={category} searchWordParam={searchWord} displayOrderParam={displayOrder} determinedsParam={data} onDisplayOrder={onDisplayOrder} onSearchSubmit={onSearchSubmit}/>
  );
}

