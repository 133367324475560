import React from "react";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { MyPageAvata } from "../../components/MyPageAvata";
import { MyPageMenu } from "../../components/MyPageMenu";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";

export const MyCouponList = () => {
  return (
    <div className="my-coupon-list">
      <div className="div-37">
        <RightMenu className="right-menu-13" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-14" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-14" />
        <NavMenu
          className="nav-menu-14"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-14" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <MyPageMenu
          className="my-page-menu-8"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=8-57&mode=design"
          href1="https://store.gpakorea.com/mypage/index/statistics"
          href2="https://store.gpakorea.com/mypage/item/fav"
          href3="https://store.gpakorea.com/mypage/index/point"
          href4="https://store.gpakorea.com/mypage/index/coupon"
          href5="https://store.gpakorea.com/mypage/index/referers"
          href6="https://store.gpakorea.com/mypage/index/voc"
          href7="https://store.gpakorea.com/mypage/index/info"
        />
        <MyPageAvata className="my-page-avata-8" />
        <div className="div-38">
          <div className="div-input-group-wrapper">
            <div className="div-input-group-3">
              <div className="input-17">
                <div className="div-placeholder-3">
                  <div className="text-wrapper-188">쿠폰코드를 입력하세요</div>
                </div>
              </div>
              <div className="span-input-group-btn-2">
                <div className="link-22">
                  <div className="text-wrapper-189">쿠폰등록</div>
                </div>
              </div>
            </div>
          </div>
          <div className="body-wrapper">
            <div className="body">
              <div className="row">
                <div className="div-ticket-wrapper">
                  <div className="div-ticket">
                    <div className="text-wrapper-190">10000원</div>
                    <img className="before-2" alt="Before" src="/img/before.svg" />
                    <img className="after-9" alt="After" src="/img/after-1.svg" />
                  </div>
                </div>
                <div className="data-3">
                  <div className="strong-5">1만원 할인쿠폰</div>
                  <div className="small-5">100,000원 이상 구매시</div>
                </div>
                <div className="data-4">
                  <div className="strong-6">
                    <div className="text-wrapper-191">미사용</div>
                  </div>
                  <div className="small-6">~2024-05-02 23:59</div>
                </div>
              </div>
              <div className="row">
                <div className="div-ticket-wrapper">
                  <div className="div-ticket">
                    <div className="text-wrapper-190">10000원</div>
                    <img className="before-2" alt="Before" src="/img/before-1.svg" />
                    <img className="after-9" alt="After" src="/img/after-2.svg" />
                  </div>
                </div>
                <div className="data-3">
                  <div className="strong-5">신규회원가입 쿠폰</div>
                  <div className="small-5">100,000원 이상 구매시</div>
                </div>
                <div className="data-4">
                  <div className="strong-6">
                    <div className="text-wrapper-191">미사용</div>
                  </div>
                  <div className="small-6">~2024-04-30 23:59</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-panel-13">
          <div className="footer-frame-14">
            <div className="company-infos-12">
              <div className="flexcontainer-13">
                <p className="text-i-14">
                  <span className="text-wrapper-192">
                    회사명: (주)차가운&nbsp;&nbsp;대표 김연섭&nbsp;&nbsp;사업자번호: 616-86-29484&nbsp;&nbsp;통신판매업:
                    제2020호-서울마포-2053호
                    <br />
                  </span>
                </p>
                <p className="text-i-14">
                  <span className="text-wrapper-192">
                    주소: 서울시 영등포구 도영로 80 209-1 (대우미래사랑 101)&nbsp;&nbsp;전화번호: 010-3930-0624
                    <br />
                  </span>
                </p>
                <p className="text-i-14">
                  <span className="text-wrapper-192">
                    이메일: chagaunnet@gmail.com Copyright Chagaun Inc. All rights reserved.
                  </span>
                </p>
              </div>
            </div>
            <div className="sns-link-14">
              <div className="list-item-link-53">
                <div className="text-wrapper-193">블로그</div>
              </div>
              <div className="list-item-link-54">
                <div className="text-wrapper-193">유튜브</div>
              </div>
              <div className="list-item-link-55">
                <div className="text-wrapper-194">페이스북</div>
              </div>
              <div className="list-item-link-56">
                <div className="text-wrapper-195">인스타그램</div>
              </div>
            </div>
            <img className="logo-gothic-14" alt="Logo gothic" src="/img/logo-gothic-1.png" />
          </div>
        </div>
        <KakaoService className="kakao-service-13" />
        <JimmedProducts className="jimmed-products-14" />
        <ViewedProducts className="viewed-products-14" />
      </div>
    </div>
  );
};
