import * as faqApi from "../api/faqs";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */

// 여러개 조회하기
const GET_FAQLIST = "GET_FAQLIST";
const GET_FAQLIST_SUCCESS = "GET_FAQLIST_SUCCESS";
const GET_FAQLIST_ERROR = "GET_FAQLIST_ERROR";

// 상품 항목 조회하기
const GET_FAQDETAIL = "GET_FAQDETAIL";
const GET_FAQDETAIL_SUCCESS = "GET_FAQDETAIL_SUCCESS";
const GET_FAQDETAIL_ERROR = "GET_FAQDETAIL_ERROR";

// 액션 생성 함수
export const getFaqList = (category, pageNumber, search) => ({
  type: GET_FAQLIST,
  payload: { category: category, page: pageNumber, search: search },
  meta: category,
});
export const getFaqDetail = (id) => ({
  type: GET_FAQDETAIL,
  payload: id,
  meta: id,
});

// GET_FAQLIST라는 액션이 발생되면 faqApi.getFaqList라는 특정 작업을 수행
const getFaqListSaga = createPromiseSagaList(GET_FAQLIST, faqApi.getFaqList);
const getFaqDetailSaga = createPromiseSagaDetail(
  GET_FAQDETAIL,
  faqApi.getFaqDetail
);

// 사가들을 합치기(특정액션(GET_FAQLIST)을 모니터링하고 있다가 액션이 발생되면 getFaqListSaga 인스턴스 실행)
export function* faqSaga() {
  yield takeEvery(GET_FAQLIST, getFaqListSaga);
  yield takeEvery(GET_FAQDETAIL, getFaqDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  faqlist: reducerUtils.initial(),
  faqdetail: reducerUtils.initial(),
};

export default function faqReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FAQLIST:
    case GET_FAQLIST_SUCCESS:
    case GET_FAQLIST_ERROR:
      const reducer = handleAsyncActionsList(GET_FAQLIST, "faqlist", true);
      return reducer(state, action);

    case GET_FAQDETAIL:
    case GET_FAQDETAIL_SUCCESS:
    case GET_FAQDETAIL_ERROR:
      const reducer2 = handleAsyncActionsDetail(
        GET_FAQDETAIL,
        "faqdetail",
        true
      );
      return reducer2(state, action);
    default:
      return state;
  }
}
