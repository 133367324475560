import React from "react";

export const CheckBox = ({id, name, checked, handleCheckItem}) => {
  const handleChecked = (e) => {
    console.log('handleChecked')
    handleCheckItem(e.target.id, e.target.checked);
  }

  return (
    <label>
      <input id={id} type="checkbox" checked={checked} onChange={handleChecked} style={{cursor:'pointer'}} />
      {name}
    </label>
  )
}