import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DeterminedListContainer } from "../../containers/DeterminedListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const DeterminedListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryParam = searchParams.get("category")
  const orderParam = searchParams.get("order")

  // 인수로 넘어온값이 없는 경우 Default 값 설정
  const category = (categoryParam && categoryParam.length > 0) ? categoryParam : 'All';
  const order = (orderParam && orderParam.length > 0) ? orderParam : 'best_desc';

  // 기존에 설정된 카테고리 클리어
  useEffect(() => {
    if (category === 'All') {
      setGlobal((global) => ({
        ...global,
        category: 'All',
        category_name: "전체",
        order: 'best_desc',
      }));
    }
  }, []);

  return (
    <DeterminedListContainer categoryParam={category} orderParam={order}/>
  );
}

