import PropTypes from "prop-types";
import React from "react";
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";

export const MyPageMenu = ({ className, href, href1, href2, href3, href4, href5, href6, href7 }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();

  // 주문 조회
  const onMyPurchaseList = () => {
    navigate("/mypurchaselist");
  };

  // 주문 통계
  const onMyPurchaseStat = () => {
    navigate("/mypurchasestat");
  };
    
  // 위시리스트
  const onMyWishList = () => {
    navigate("/mywishlist");
  };
    
  // 캐시내역
  const onMyCacheList = () => {
    navigate("/mycachelist");
  };
    
  // 쿠폰리스트
  const onMyCouponList = () => {
    navigate("/mycouponlist");
  };
    
  // 추천시스템
  const onMyInviteLink = () => {
    navigate("/myinvitelink");
  };
    
  // 일대일문의
  const onInquiryList = () => {
    navigate("/inquirylist");
  };
    
  // 내정보수정
  const onMyInfoUpdate = () => {
    navigate("/myinfoupdate");
  };
    
  return (
    <div className={`my-page-menu ${className}`}>
      <div className="my-page-sub-menu">
        <div className="span-wrapper">
          <div className="span">
            <div className="text-wrapper-11" onClick={onMyPurchaseList} rel="noopener noreferrer" target="_blank">
              주문조회
            </div>
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-12" onClick={onMyPurchaseStat} rel="noopener noreferrer" target="_blank">
            주문통계
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-12" onClick={onMyWishList} rel="noopener noreferrer" target="_blank">
            위시리스트
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-12" onClick={onMyCacheList} rel="noopener noreferrer" target="_blank">
            캐시내역
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-12" onClick={onMyCouponList} rel="noopener noreferrer" target="_blank">
            쿠폰
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-12" onClick={onMyInviteLink} rel="noopener noreferrer" target="_blank">
            추천시스템
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-12" onClick={onInquiryList} rel="noopener noreferrer" target="_blank">
            1:1문의
          </div>
        </div>
        <div
          className="item-link-7"
          rel="noopener noreferrer"
          target="_blank"
        >
          <div className="text-wrapper-12" onClick={onMyInfoUpdate} rel="noopener noreferrer" target="_blank">
            정보 수정
          </div>
        </div>
      </div>
      <div className="div-wrapper-2">
        <div className="text-wrapper-13">마이페이지</div>
      </div>
    </div>
  );
};

MyPageMenu.propTypes = {
  href: PropTypes.string,
  href1: PropTypes.string,
  href2: PropTypes.string,
  href3: PropTypes.string,
  href4: PropTypes.string,
  href5: PropTypes.string,
  href6: PropTypes.string,
  href7: PropTypes.string,
};
