import AuthApi from "../api/auth"; // api/posts 안의 함수 모두 불러오기
import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext } from "redux-saga/effects";

/* 액션 타입 */
const AUTH = "AUTH";
const AUTH_SUCCESS = "AUTH_SUCCESS";
const AUTH_ERROR = "AUTH_ERROR";

export const getAuth = (id) => ({ type: AUTH, payload: id, meta: id });

const getAuthSaga = createPromiseSagaDetail(AUTH, AuthApi.Login);

// 사가들을 합치기
export function* authSaga() {
  yield takeEvery(AUTH, getAuthSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  authkey: reducerUtils.initial(),
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AUTH:
    case AUTH_SUCCESS:
    case AUTH_ERROR:
      const reducer = handleAsyncActionsList(AUTH, "authkey", true);
      return reducer(state, action);
    default:
      return state;
  }
}
