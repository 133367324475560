import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ProductListPage } from "./screens/ProductListPage/ProductListPage";
import { ProductDetailPage } from "./screens/ProductDetailPage";
import { DeterminListPage } from "./screens/DeterminListPage/DeterminListPage";
import { DeterminDetailPage } from "./screens/DeterminDetailPage/DeterminDetailPage";
import { DeterminedListPage } from "./screens/DeterminedListPage/DeterminedListPage";
import { DeterminedDetailPage } from "./screens/DeterminedDetailPage/DeterminedDetailPage";
import { MyInfoUpdate } from "./screens/MyInfoUpdate";
import { LoginPage } from "./screens/LoginPage";
import { LoginRedirectNaverPage } from "./screens/LoginPage";
import { BulletinListPage } from "./screens/BulletinListPage";
import { BulletinDetailPage } from "./screens/BulletinDetailPage";
import { FaqListPage } from "./screens/FaqListPage";
import { FaqDetailPage } from "./screens/FaqDetailPage";
import { MyInviteLink } from "./screens/MyInviteLink";
import { SignupSelectPage } from "./screens/SignupSelectPage";
import { SignupAgreePage } from "./screens/SignupAgreePage/SignupAgreePage";
import { SignupRegistryPage } from "./screens/SignupRegistryPage";
import { MyPurchaseList } from "./screens/MyPurchaseList";
import { MyPurchaseStat } from "./screens/MyPurchaseStat";
import { MyCacheList } from "./screens/MyCacheList";
import { MyWishList } from "./screens/MyWishList";
import { MyCouponList } from "./screens/MyCouponList";
import { PurchaseVirtual } from "./screens/PurchaseVirtual";
import { PurchaseCard } from "./screens/PurchaseCard";
import { InquiryListPage } from "./screens/InquiryListPage";
import { InquiryCreatePage } from "./screens/InquiryCreatePage/InquiryCreatePage";
import { InquiryDetailPage } from "./screens/InquiryDetailPage";
import { TermsOfUse } from "./screens/TermsOfUsePage";
import { PersonalPrivacy } from "./screens/PersonalPrivacyPage";
import { RefundCancel } from "./screens/RefundCancel";
import { PurchaseMutong } from "./screens/PurchaseMutong";
import { CacheDepositMutong } from "./screens/CacheDepositMutong";
import { CacheDeposit } from "./screens/CacheDeposit";
import { ProductBasketsPage } from "./screens/ProductBasketsPage";

const router = createBrowserRouter([
  {
    path: "/*",
    element: <ProductListPage />,
  },
  {
    path: "/productlist",
    element: <ProductListPage />,
  },
  {
    path: "/productdetail/:id",
    element: <ProductDetailPage />,
  },
  {
    path: "/determin",
    element: <DeterminListPage />,
  },
  {
    path: "/determindetail/:_id",
    element: <DeterminDetailPage />,
  },
  {
    path: "/determined",
    element: <DeterminedListPage />,
  },
  {
    path: "/determineddetail/:id",
    element: <DeterminedDetailPage />,
  },
  {
    path: "/myinfoupdate",
    element: <MyInfoUpdate />,
  },
  {
    path: "/inquirylist",
    element: <InquiryListPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/redirectnaver",
    element: <LoginRedirectNaverPage />,
  },
  {
    path: "/myinvitelink",
    element: <MyInviteLink />,
  },
  {
    path: "/signupselect",
    element: <SignupSelectPage />,
  },
  {
    path: "/signupagree",
    element: <SignupAgreePage />,
  },
  {
    path: "/signupregistry",
    element: <SignupRegistryPage />,
  },
  {
    path: "/mypurchaselist",
    element: <MyPurchaseList />,
  },
  {
    path: "/mypurchasestat",
    element: <MyPurchaseStat />,
  },
  {
    path: "/mycachelist",
    element: <MyCacheList />,
  },
  {
    path: "/mywishlist",
    element: <MyWishList />,
  },
  {
    path: "/mycouponlist",
    element: <MyCouponList />,
  },
  {
    path: "/bulletinlist",
    element: <BulletinListPage />,
  },
  {
    path: "/bulletindetail/:id",
    element: <BulletinDetailPage />,
  },
  {
    path: "/faqlist",
    element: <FaqListPage />,
  },
  {
    path: "/faqdetail/:id",
    element: <FaqDetailPage />,
  },
  {
    path: "/purchasevirtual",
    element: <PurchaseVirtual />,
  },
  {
    path: "/purchasecard",
    element: <PurchaseCard />,
  },
  {
    path: "/inquirycreate",
    element: <InquiryCreatePage />,
  },
  {
    path: "/inquirydetail/:id",
    element: <InquiryDetailPage />,
  },
  {
    path: "/termsofuse",
    element: <TermsOfUse />,
  },
  {
    path: "/personalprivacy",
    element: <PersonalPrivacy />,
  },
  {
    path: "/refundcancel",
    element: <RefundCancel />,
  },
  {
    path: "/purchasemutong",
    element: <PurchaseMutong />,
  },
  {
    path: "/cachedepositmutong",
    element: <CacheDepositMutong />,
  },
  {
    path: "/cachedepositvirtual",
    element: <CacheDeposit />,
  },
  {
    path: "/productbaskets",
    element: <ProductBasketsPage />,
  },
]);

export const App = () => {
  return <RouterProvider router={router} />;
};
