import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Login } from "../components/Login";


export const LoginContainer = () => {
  return (
    <Login />
  );
}

