import React from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { CustomerMenu } from "../../components/CustomerMenu";
import { JimmedProducts } from "../../components/JimmedProducts";
import { KakaoService } from "../../components/KakaoService";
import { LinkTree } from "../../components/LinkTree";
import { MainLogo } from "../../components/MainLogo";
import { NavMenu } from "../../components/NavMenu";
import { RightMenu } from "../../components/RightMenu";
import { SearchArea } from "../../components/SearchArea";
import { ViewedProducts } from "../../components/ViewedProducts";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { FooterPanel } from "./FooterPanel";

export const BulletinList = ({ bulletins, pageInfo, onPageSubmit, onSearchSubmit }) => {
  return (
    <div className="bulletin-list">
      <div className="div-4">
        <RightMenu className="right-menu-2" href="https://store.gpakorea.com/cs" />
        <MainLogo className="main-logo-2" href="https://store.gpakorea.com/" />
        <SearchArea className="search-area-2" />
        <NavMenu
          className="nav-menu-2"
          href="https://store.gpakorea.com/item/category/3"
          href1="https://store.gpakorea.com/item/category/8"
          href2="https://store.gpakorea.com/item/category/9"
          href3="https://store.gpakorea.com/item/category/20"
          href4="https://store.gpakorea.com/item/category/21"
          href5="https://store.gpakorea.com/item/category/22"
          href6="https://store.gpakorea.com/item/category/92"
        />
        <LinkTree className="link-tree-2" href="https://store.gpakorea.com/" href1="https://store.gpakorea.com/" />
        <CustomerMenu
          className="customer-menu-2"
          href="https://www.figma.com/file/LgfOht5QjmTS0vUSwbAdQu?type=design&node-id=10-114&mode=design"
          href1="https://store.gpakorea.com/cs/faq"
          href2="https://store.gpakorea.com/cs/voc"
          href3="https://store.gpakorea.com/cs/terms_of_use"
          href4="https://store.gpakorea.com/cs/privacy_policy"
          href5="https://store.gpakorea.com/cs/return_policy"
        />
        <div className="bulletin-body">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="span-bar-2" />
              <div className="heading">공지사항</div>
            </div>
            <div className="bulletin-body-table">
              <div className="form-table-2">
                <div className="body-row-cell-5">
                  <div className="text-wrapper-46">번호</div>
                </div>
                <div className="body-row-cell-6">
                  <div className="text-wrapper-47">제목</div>
                </div>
                <div className="body-row-cell-7">
                  <div className="text-wrapper-48">글쓴이</div>
                </div>
                <div className="body-row-cell-8">
                  <div className="text-wrapper-49">날짜</div>
                </div>
                <div className="body-row-cell-9">
                  <div className="text-wrapper-49">조회</div>
                </div>

                <ul>
                  {bulletins.map((bulletin, index, total) => (
                    <li key={bulletin.id}>
                      <div className={`body-row-data-${81+5*index}`}>
                        <div className="text-wrapper-57">{bulletin.id}</div>
                      </div>
                      <Link to={`/bulletindetail/${bulletin.id}`}>
                        <div className={`body-row-data-${82+5*index}`}>
                          <div className="text-wrapper-51">{bulletin.subject}</div>
                        </div>
                      </Link>
                      <div className={`body-row-data-${83+5*index}`}>
                        <div className="text-wrapper-52">{bulletin.author.substr(0,5)}</div>
                      </div>
                      <div className={`body-row-data-${84+5*index}`}>
                        <div className="text-wrapper-53">{bulletin.created_at.substr(2,8)}</div>
                      </div>
                      <div className={`body-row-data-${85+5*index}`}>
                        <div className="text-wrapper-54">{bulletin.view.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>
                      </div>
                    </li>
                  ))}
                </ul>


              </div>
            </div>
            <BodyPage 
              className="body-page-2" 
              pageInfo={ pageInfo } 
              onPageSubmit={onPageSubmit}
            />
            <BodySearch 
              className="body-search-2" 
              formInputClassName="body-search-3" 
              pageInfo={pageInfo}
              onSearchSubmit={onSearchSubmit} 
            />
          </div>
        </div>
        <FooterPanel />
        <KakaoService className="kakao-service-2" />
        <JimmedProducts className="jimmed-products-2" />
        <ViewedProducts className="viewed-products-2" />
      </div>
    </div>
  );
};
