import React, { useEffect } from 'react';
import "./style.css";

// interface Props {
//   message: string;
//   onClickOK: () => void;
//   onClickCancel: () => void;
// }

const Confirm = ({ message, onClickOK, onClickCancel }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClickCancel();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClickCancel]);

  return (
    <div className="dialog-container">
      <div className="overlay" onClickCapture={(e) => e.stopPropagation()} />
      <div className="dialog">
        <h2 className="title">ARGUS</h2>
        <div className="text">{message}</div>
        <div className="buttons">
          <button onClick={onClickCancel}>&nbsp;아&nbsp;니&nbsp;오&nbsp;</button>
          <button onClick={onClickOK} autoFocus>&nbsp;예&nbsp;</button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
