import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInquiryDetail } from "../modules/inquirys";
import InquiryDetail from "../components/InquiryDetail/InquiryDetail.jsx";

// 
export const InquiryDetailContainer = ( { id }) => {
  const { data, loading, error } = useSelector(
    (state) => state.inquiryReducer.inquirydetail[id]
    ) || {
      loading: false,
      data: null,
      error: null,
    };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInquiryDetail(id));
  }, [dispatch, id]);

  if (loading) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;

  return (
    <InquiryDetail 
      inquiry={data}
    />
  );
}

