import React, { useState } from "react";
import "./style.css";
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";

export const SubMenu = ({category, onCategory}) => {
    const { user, setUser, global, setGlobal } = useAuth();
    const navigate = useNavigate();

    return(
        <div className="inquiry-body-menu">
            <div className="list-item-link" style={{background: category == '' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("")}} >
                <div className="text-wrapper-25" style={{color: category == '' ? '#ffffff' : '#666666'}}>전체</div>
            </div>
            <div className="list-item-link-2" style={{background: category == '일반문의' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("일반문의")}} >
                <div className="text-wrapper-26" style={{color: category == '일반문의' ? '#ffffff' : '#666666'}}>일반문의</div>
            </div>
            <div className="list-item-link-3" style={{background: category == '제안관련' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("제안관련")}} >
                <div className="text-wrapper-26" style={{color: category == '제안관련' ? '#ffffff' : '#666666'}}>제안관련</div>
            </div>
            <div className="list-item-link-4" style={{background: category == '버그관련' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("버그관련")}} >
                <div className="text-wrapper-27" style={{color: category == '버그관련' ? '#ffffff' : '#666666'}}>버그관련</div>
            </div>
            <div className="list-item-link-5" style={{background: category == '탈퇴관련' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("탈퇴관련")}} >
                <div className="text-wrapper-26" style={{color: category == '탈퇴관련' ? '#ffffff' : '#666666'}}>탈퇴관련</div>
            </div>
            <div className="list-item-link-6" style={{background: category == '기타' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("기타")}} >
                <div className="text-wrapper-26" style={{color: category == '기타' ? '#ffffff' : '#666666'}}>기타</div>
            </div>
        </div>

    );
}
// export default SubMenu;