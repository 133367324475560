import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ProductBasketList } from "../components/ProductBasketList/ProductBasketList";
import { getProductBaskets } from "../modules/products";
import { useAuth } from "../contexts/auth/auth.context";

export const ProductBasketsContainer = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const { data, loading, error } = useSelector(
    (state) => state.productReducer.productbaskets
  );
  const dispatch = useDispatch();
  console.log(`ProductBasketsContainer:시작`)

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    console.log('ProductBasketsContainer:마운트');
    dispatch(getProductBaskets(user.id, user.access));

    return() => {
      console.log('ProductBasketsContainer:언마운트');
    }    
  }, [dispatch]);

  const onPageSubmit = (paramNumber) => {
    setCurrPage(paramNumber);
  }

  const onSearchSubmit = (paramSearch) => {
    if (paramSearch != "") {
      setCurrPage(1);
      setSearch(paramSearch);
    }
  }

  if (loading && !data) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  if (!data) return null;
  
  console.log(`ProductBasketsContainer:data=${data.length}`)

  return (
    <ProductBasketList bks={data} />
  );
}

