import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoginRedirectNaverURI } from "../components/Login";  // /LoginRedirectNaver


export const LoginRedirectNaverContainer = () => {
  return (
    <LoginRedirectNaverURI />
  );
}

