import axios from "./index";

export const getFaqList = async (params) => {
  const response = await axios.get("/faqs/", { params: params });
  return response.data;
};
export const getFaqDetail = async (id) => {
  const response = await axios.get(`/faqs/${id}/`);
  return response.data;
};
