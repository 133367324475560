import React, { useState } from "react";
import "./style.css";

export const SearchArea = ({ className, onSearchSubmit }) => {
  const [formData, setFormData] = useState({
    'search': ''
  });
  const { search } = formData; 

  const onChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const onKeyDown = (e) => {
    if(e.key === "Enter" && search != "") {
      onSearchSubmit(search);
    }
  } 

  const onClick = (e) => {
    e.preventDefault();
    if (search != "") {
      onSearchSubmit(search);
    } 
  } 

  return (
    <div className={`search-area ${className}`} >
      <input className="input-box" type="text" id="search" name="search" value={search} onChange={onChange} onKeyDown={(e) => onKeyDown(e)} placeholder='검색어를 입력하세요'/>
      <button className="search" style={{cursor: 'pointer', border: 'none'}} onClick={onClick}>
        <div className="text-wrapper-54"></div>
      </button>
    </div>
  );
};