import { useParams } from "react-router";
import { BulletinDetailContainer } from "../../containers/BulletinDetailContainer";
import { BulletinListContainer } from "../../containers/BulletinListContainer";

export const BulletinDetailPage = () => {
  const { id } = useParams();  
  return (
    (!isNaN(+id) === true) ?
    <BulletinDetailContainer id={parseInt(id)} /> :
    <BulletinListContainer />
  );
}

