import { useParams } from "react-router";
import { FaqDetailContainer } from "../../containers/FaqDetailContainer";

export const FaqDetailPage = () => {
  const { id } = useParams();  
  return (
    (!isNaN(+id) === true) ?
    <FaqDetailContainer id={parseInt(id)} /> :
    <FaqListContainer />
  );
}

