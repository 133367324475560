import React, { useState } from "react";
import "./style.css";
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from "../../contexts/auth/auth.context";

export const SubMenu = ({category, onCategory}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="faq-body-menu">
      <div className="list-item-link" style={{background: category == '' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("")}} >
        <div className="text-wrapper-22" style={{color: category == '' ? '#ffffff' : '#666666'}}>전체</div>
      </div>
      <div className="list-item-link-2" style={{background: category == '회원' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("회원")}}>
        <div className="text-wrapper-23" style={{color: category == '회원' ? '#ffffff' : '#666666'}}>회원</div>
      </div>
      <div className="list-item-link-3" style={{background: category == '결제' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("결제")}}>
        <div className="text-wrapper-23" style={{color: category == '결제' ? '#ffffff' : '#666666'}}>결제</div>
      </div>
      <div className="list-item-link-4" style={{background: category == '구독' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("구독")}}>
        <div className="text-wrapper-24" style={{color: category == '구독' ? '#ffffff' : '#666666'}}>구독</div>
      </div>
      <div className="list-item-link-5" style={{background: category == '클리커' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("클리커")}}>
        <div className="text-wrapper-23" style={{color: category == '클리커' ? '#ffffff' : '#666666'}}>클리커</div>
      </div>
      <div className="list-item-link-6" style={{background: category == '기타' ? '#656978' : '#ffffff'}} onClick={() => {onCategory("기타")}}>
        <div className="text-wrapper-23" style={{color: category == '기타' ? '#ffffff' : '#666666'}}>기타</div>
      </div>
    </div>
  );
}


